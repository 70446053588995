import { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import useTheme from "../../hooks/useThemes";
import cn from "classnames";

interface TableViewItemProps {
  itemViewCount: number;
  setItemViewCount: Dispatch<SetStateAction<number>>;
}

const TableViewItem: FC<TableViewItemProps> = ({ itemViewCount, setItemViewCount }) => {
  const { theme } = useTheme();

  const handleItemViewCountChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setItemViewCount(Number(e.target.value));
  };

  return (
    <div className="col-md-6 text-nowrap">
      <div className="dataTables_length">
        <label className={cn("form-label", theme === "light" ? "text-black" : "text-white")} htmlFor={"selectViewItem"}>
          Показывать&nbsp;
          <select
            className="d-inline-block form-select form-select-sm"
            id="selectViewItem"
            value={itemViewCount}
            onChange={handleItemViewCountChange}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          &nbsp;
        </label>
      </div>
    </div>
  );
};

export default TableViewItem;
