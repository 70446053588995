import { apiAdmin } from "../core/axios";
import { IApiAdminUserDataResponse } from "../types/IAdmin";
import { ISoftwareVersionBySoftwareID, ISoftwareVersionResponse } from "../types/ISoftware";
import { ICompaniesAndContract, ICompaniesResponse, ICompanyTableDataResponse } from "../types/ICompany";
import {
  IContractNumbersByCompanyID,
  IContractsWithCompanyDataResponse,
  IContractWithUserAndCompanyByCompanyIDResponse
} from "../types/IContract";
import { ApiLicenseWithUserResponse, ILicenseInfo } from "../types/ILicenses";
import { IUser } from "../types/IUser";
import { IAuditLogEntryResponse } from "../types/IAudit";
import { IDashboard, IOsStats } from "../types/IDashboard";


interface IRawUsers {
  id: number;
  company_name: string;
  email: string;
  role_name: string;
  is_banned: boolean;
  last_visited_at: string;
  created_at: string;
}

interface IRawUsersDataResponse {
  data: IRawUsers[];
  count: number;
  lastPage: number;
  currentPage: number;
}

const transformUsers = (rawResponse: IRawUsersDataResponse): IApiAdminUserDataResponse => {
  return {
    data: rawResponse.data.map((item: IRawUsers) => ({
      ID: item.id,
      companyName: item.company_name,
      email: item.email,
      roleName: item.role_name,
      isBanned: item.is_banned,
      lastVisitedAt: item.last_visited_at,
      createdAt: item.created_at
    })),
    lastPage: rawResponse.lastPage,
    currentPage: rawResponse.currentPage,
    count: rawResponse.count
  };
};

interface IRawSoftwareVersion {
  id: number;
  name: string;
  version: string;
  release_date: string;
  is_active: boolean;
  is_full_release: boolean;
  description: string;
  operation_system_name: string;
  change_log: string;
  file_name: string;
  file_size: number;
  file_type: string;
  file_crc_32: number;
  download_count: number;
  created_at: string;
  branch: string;
}

interface IRawSoftwareVersionResponse {
  data: IRawSoftwareVersion[];
  count: number;
  lastPage: number;
  currentPage: number;
}


const transformSoftwareVersions = (rawResponse: IRawSoftwareVersionResponse): ISoftwareVersionResponse => {
  if (rawResponse === null)
    return null;

  const data = rawResponse.data.map((item: IRawSoftwareVersion) => ({
    ID: item.id,
    name: item.name,
    version: item.version,
    releaseDate: item.release_date,
    isActive: item.is_active,
    isFullRelease: item.is_full_release,
    description: item.description,
    operationSystemName: item.operation_system_name,
    changeLog: item.change_log,
    fileName: item.file_name,
    fileSize: item.file_size,
    fileType: item.file_type,
    fileCrc32: item.file_crc_32,
    downloadCount: item.download_count,
    createdAt: item.created_at,
    branch: item.branch,
  }));

  return {
    data: data,
    count: rawResponse.count,
    lastPage: rawResponse.lastPage,
    currentPage: rawResponse.currentPage
  };
};

interface IRawSoftwareVersionUser {
  id: number;
  name: string;
  surname: string;
  email: string;
  download_date: string;
}

interface IRawSoftwareVersionBySoftwareID {
  id: number;
  software_name: string;
  version: string;
  release_date: string;
  is_active: boolean;
  is_full_release: boolean;
  description: string;
  operation_system_id: number;
  change_log: string;
  file: {
    name: string;
    old_name: string;
    size: number;
    type: string;
    crc32: number;
    download_count: number;
    users: IRawSoftwareVersionUser[];
  };
  created_at: string;
  updated_at: string;
  uploader_id: number;
  uploader: string;
}

const transformSoftwareVersionsBySoftwareID = (item: IRawSoftwareVersionBySoftwareID): ISoftwareVersionBySoftwareID => {
  return {
    ID: item.id,
    name: item.software_name,
    version: item.version,
    releaseDate: item.release_date,
    isActive: item.is_active,
    isFullRelease: item.is_full_release,
    description: item.description,
    operationSystemID: item.operation_system_id,
    changeLog: item.change_log,
    file: {
      name: item.file.name,
      oldName: item.file.old_name,
      size: item.file.size,
      type: item.file.type,
      crc32: item.file.crc32,
      downloadCount: item.file.download_count,
      users: item.file.users.map((u: IRawSoftwareVersionUser) => ({
        id: u.id,
        name: u.name,
        surname: u.surname,
        email: u.email,
        downloadDate: u.download_date
      }))
    },
    createdAt: item.created_at,
    updatedAt: item.updated_at,
    uploaderID: item.uploader_id,
    uploader: item.uploader
  };
};


interface IRawCompanyContract {
  id: number;
  name: string;
  contract_count: number;
  contract_order_count: number;
  created_at: string;
}

interface IRawCompanyAndContractsResponse {
  data: IRawCompanyContract[];
  count: number;
  lastPage: number;
  currentPage: number;
}

const transformCompanyAndContract = (rawResponse: IRawCompanyAndContractsResponse): ICompanyTableDataResponse => {
  if (rawResponse === null)
    return null;

  const data = rawResponse.data.map((item: IRawCompanyContract) => ({
    ID: item.id,
    name: item.name,
    contractCount: item.contract_count,
    contractOrderCount: item.contract_order_count,
    createdAt: item.created_at
  }));

  return {
    data: data,
    count: rawResponse.count,
    lastPage: rawResponse.lastPage,
    currentPage: rawResponse.currentPage
  };
};

interface IRawContractsWithCompany {
  company_id: number;
  company_name: string;
  contract_id: number;
  contract_number: string;
  created_at: string;
  date_of_conclusion: string;
  is_order_number_exist: boolean;
  lic_available: number;
  lic_count: number;
  updated_at: string;
}

interface IRawContractsWithCompanyDataResponse {
  data: IRawContractsWithCompany[];
  count: number;
  lastPage: number;
  currentPage: number;
}

const transformContractsWithCompany = (rawResponse: IRawContractsWithCompanyDataResponse): IContractsWithCompanyDataResponse => {
  if (rawResponse === null)
    return null;

  const data = rawResponse.data.map((item: IRawContractsWithCompany) => ({
    companyID: item.company_id,
    companyName: item.company_name,
    contractID: item.contract_id,
    contractNumber: item.contract_number,
    dateOfConclusion: item.date_of_conclusion,
    isOrderNumberExist: item.is_order_number_exist,
    licAvailable: item.lic_available,
    licCount: item.lic_count,
    updatedAt: item.updated_at,
    createdAt: item.created_at
  }));

  return {
    data: data,
    count: rawResponse.count,
    lastPage: rawResponse.lastPage,
    currentPage: rawResponse.currentPage
  };
};

interface IRawContractIDAndName {
  contract_id: number;
  contract_number: string;
}

interface IRawCompanyAndContract {
  company_id: number;
  company_name: string;
  contracts: IRawContractIDAndName[];
}

type IRawCompaniesAndContract = IRawCompanyAndContract[];

const transformCompaniesAndContract = (rawResponse: IRawCompaniesAndContract): ICompaniesAndContract => {
  return rawResponse.map((companyItem => ({
    companyID: companyItem.company_id,
    companyName: companyItem.company_name,
    contracts: companyItem.contracts.map((contractItem => ({
      contractID: contractItem.contract_id,
      contractNumber: contractItem.contract_number
    })))
  })));
};

interface IRawCompany {
  id: number;
  name: string;
  created_at: string;
}

interface IRawCompanies {
  data: IRawCompany[];
  count: number;
}

const transformCompany = (rawResponse: IRawCompanies): ICompaniesResponse => {
  return {
    data: rawResponse.data.map((item => ({
      ID: item.id,
      name: item.name,
      createdAt: item.created_at
    }))),
    count: rawResponse.count
  };
};


interface IRawApiResponse {
  count: number;
  currentPage: number;
  lastPage: number;
  companyName: string;
  contractNumber: string;
  data: IRawLicense[];
}


export interface IRawLicense {
  id: number;
  license_number: string;
  expiration_date: string;
  hw_fix: boolean;
  lic_version: number;
  ccb_old_file_name: string | null;
  ccb_file_name: string | null;
  lic_file_name: string | null;
  issue_complete: boolean;
  issue_date: string | null;
  user_id: number | null;
  user_name: string | null;
  user_surname: string | null;
}


const transformLicenses = (rawResponse: IRawApiResponse): ApiLicenseWithUserResponse => {
  const data = rawResponse.data.map(rawLicense => ({
    ID: rawLicense.id,
    licenseNumber: rawLicense.license_number,
    expirationDate: rawLicense.expiration_date,
    hwFix: rawLicense.hw_fix,
    licVersion: rawLicense.lic_version,
    ccbOldFileName: rawLicense.ccb_old_file_name,
    ccbFileName: rawLicense.ccb_file_name,
    licFileName: rawLicense.lic_file_name,
    issueComplete: rawLicense.issue_complete,
    issueDate: rawLicense.issue_date,
    userID: rawLicense.user_id,
    userName: rawLicense.user_name,
    userSurname: rawLicense.user_surname
  }));

  return {
    count: rawResponse.count,
    currentPage: rawResponse.currentPage,
    lastPage: rawResponse.lastPage,
    companyName: rawResponse.companyName,
    contractNumber: rawResponse.contractNumber,
    data: data
  };
};

interface IRawLicenseInfo {
  id: number;
  license_number: string;
  hw_fix: boolean;
  never_expire: boolean;
  date_of_issue: string;
  expiration_date: string;
  lic_version: number;
  issue_complete: boolean;
  issue_date: string;
  description: string;
  updated_at: string;
  created_at: string;
  company_name: string;
  contract_number: string;
  user_name: string;
  user_surname: string;
  user_email: string;
  ccb_old_file_name: string;
  ccb_file_name: string;
  lic_file_name: string;
  plugins: {
    smtcp: boolean;
    opc_ua: boolean;
    modbus: boolean;
    redundancy: boolean;
    elna: boolean;
    online_transfer: boolean;
    archiver: boolean;
  };
}

const transformLicenseInfo = (rawResponse: IRawLicenseInfo): ILicenseInfo => {
  return {
    ID: rawResponse.id,
    LicenseNumber: rawResponse.license_number,
    HwFix: rawResponse.hw_fix,
    NeverExpire: rawResponse.never_expire,
    DateOfIssue: rawResponse.date_of_issue,
    ExpirationDate: rawResponse.expiration_date,
    LicVersion: rawResponse.lic_version,
    IssueComplete: rawResponse.issue_complete,
    IssueDate: rawResponse.issue_date,
    Description: rawResponse.description,
    UpdatedAt: rawResponse.updated_at,
    CreatedAt: rawResponse.created_at,
    CompanyName: rawResponse.company_name,
    ContractNumber: rawResponse.contract_number,
    UserName: rawResponse.user_name,
    UserSurname: rawResponse.user_surname,
    UserEmail: rawResponse.user_email,
    CcbOldFileName: rawResponse.ccb_old_file_name,
    CcbFileName: rawResponse.ccb_file_name,
    LicFileName: rawResponse.lic_file_name,
    Plugins: {
      Smtcp: rawResponse.plugins.smtcp,
      OpcUa: rawResponse.plugins.opc_ua,
      Modbus: rawResponse.plugins.modbus,
      Redundancy: rawResponse.plugins.redundancy,
      Elna: rawResponse.plugins.elna,
      OnlineTransfer: rawResponse.plugins.online_transfer,
      Archiver: rawResponse.plugins.archiver
    }
  };
};

interface IRawContractWithUserAndCompanyByCompanyID {
  contract_id: number;
  contract_number: string;
  created_at: string;
  date_of_conclusion: string;
  is_order_number_exist: boolean;
  lic_available: number;
  lic_count: number;
  updated_at: string;
  user_id: number;
  user_name: string;
  user_surname: string;
  company_name: string;
  warranty_exist: boolean;
}

interface IRawContractWithUserAndCompanyByCompanyIDResponse {
  data: IRawContractWithUserAndCompanyByCompanyID[];
  count: number;
  currentPage: number;
  lastPage: number;
}


const transformContractsByCompanyID = (rawResponse: IRawContractWithUserAndCompanyByCompanyIDResponse): IContractWithUserAndCompanyByCompanyIDResponse => {
  return {
    data: rawResponse.data.map((item: IRawContractWithUserAndCompanyByCompanyID) => ({
      ContractID: item.contract_id,
      ContractNumber: item.contract_number,
      CreatedAt: item.created_at,
      DateOfConclusion: item.date_of_conclusion,
      IsOrderNumberExist: item.is_order_number_exist,
      LicAvailable: item.lic_available,
      LicCount: item.lic_count,
      UpdatedAt: item.updated_at,
      UserID: item.user_id,
      UserName: item.user_name,
      UserSurname: item.user_surname,
      CompanyName: item.company_name,
      WarrantyExist: item.warranty_exist
    })),
    count: rawResponse.count,
    currentPage: rawResponse.currentPage,
    lastPage: rawResponse.lastPage
  };
};

type IRawChangeEntry = {
  old_value: string | number | boolean | null;
  new_value: string | number | boolean | null;
  changed: boolean;
};

type IRawChanges = Record<string, IRawChangeEntry>;

type IRawAuditLogEntry = {
  id: number;
  table_name: string;
  operation: "INSERT" | "UPDATE" | "DELETE";
  changes: IRawChanges;
  changed_on: string;
};

export interface IRawAuditLogEntryResponse {
  data: IRawAuditLogEntry[];
  count: number;
  currentPage: number;
  lastPage: number;
}

const transformAudit = (rawResponse: IRawAuditLogEntryResponse): IAuditLogEntryResponse => {
  return {
    data: rawResponse.data.map((item) => ({
      ID: item.id,
      operation: item.operation,
      tableName: item.table_name,
      changes: Object.fromEntries(
        Object.entries(item.changes).map(([key, value]) => [
          key,
          {
            oldValue: value.old_value,
            newValue: value.new_value,
            changed: value.changed
          }
        ])
      ),
      changedOn: item.changed_on
    })),
    count: rawResponse.count,
    lastPage: rawResponse.lastPage,
    currentPage: rawResponse.currentPage
  };
};

interface IRawContractNumbersByCompanyID {
  id: number;
  contract_number: string;
}

const transformContractNumbersByCompanyID = (rawResponse: IRawContractNumbersByCompanyID[]): IContractNumbersByCompanyID[] => {
  return rawResponse.map((item) => ({
    ID: item.id,
    ContractNumber: item.contract_number
  }));
};

interface IRawDashboardResponse {
  license_statistics: {
    licenses_issued_per_year: number;
    licenses_issued_per_month: number;
    licenses_issued_per_day: number;
    percent_contract_issued: number;
  },
  download_software_count: number;
  total_licenses_issued: number;
  total_users_registered: number;
  total_company_registered: number;
  total_contracts_registered: number;
}

const transformDashboard = (rawResponse: IRawDashboardResponse): IDashboard => {
  return {
    licenseStatistics: {
      licensesIssuedPerYear: rawResponse.license_statistics.licenses_issued_per_year,
      licensesIssuedPerMonth: rawResponse.license_statistics.licenses_issued_per_month,
      licensesIssuedPerDay: rawResponse.license_statistics.licenses_issued_per_day,
      percentContractIssued: rawResponse.license_statistics.percent_contract_issued
    },
    downloadSoftwareCount: rawResponse.download_software_count,
    totalLicensesIssued: rawResponse.total_licenses_issued,
    totalUsersRegistered: rawResponse.total_users_registered,
    totalCompanyRegistered: rawResponse.total_company_registered,
    totalContractsRegistered: rawResponse.total_contracts_registered
  };
};

export interface IRawOsStats {
  mem_total: number;
  mem_available: number;
  mem_used: number;
  hdd_mem_total: number;
  hdd_mem_available: number;
  hdd_mem_used: number;
  uptime: string;
  avg_stat: {
    load1: number;
    load5: number;
    load15: number;
  };
}

const transformOsStats = (rawResponse: IRawOsStats): IOsStats => {
  return {
    memTotal: rawResponse.mem_total,
    memAvailable: rawResponse.mem_available,
    memUsed: rawResponse.mem_used,
    hddMemTotal: rawResponse.hdd_mem_total,
    hddMemAvailable: rawResponse.hdd_mem_available,
    hddMemUsed: rawResponse.hdd_mem_used,
    uptime: rawResponse.uptime,
    avgStat: {
      load1: rawResponse.avg_stat.load1,
      load5: rawResponse.avg_stat.load5,
      load15: rawResponse.avg_stat.load15
    }
  };
};

export const adminService = {
  async getDashboard() {
    const response = await apiAdmin.get<IRawDashboardResponse>("/api/dashboard");
    return transformDashboard(response.data);
  },

  async getStats() {
    const response = await apiAdmin.get("/api/stats");
    return transformOsStats(response.data);
  },

  async uploadFile(formData: FormData) {
    return await apiAdmin.post("/api/file/chunk", formData);
  },

  async createSoftwareUpdate(formData: any) {
    return await apiAdmin.post("/api/software/", formData);
  },

  async getCompaniesContract(limit: number, page: number): Promise<ICompanyTableDataResponse> {
    const response = await apiAdmin.get<IRawCompanyAndContractsResponse>("/api/company/contract", {
      params: {
        limit: limit,
        page: page
      }
    });

    return transformCompanyAndContract(response.data);
  },

  async createCompany(data: any) {
    return await apiAdmin.post("/api/company/", data);
  },

  async getUsers(limit: number, page: number) {
    const response = await apiAdmin.get("/api/users/", {
      params: {
        limit: limit,
        page: page
      }
    });

    return transformUsers(response.data);
  },

  async createUser(data: any) {
    return await apiAdmin.post("api/users/", data);
  },

  async getCompany() {
    const response = await apiAdmin.get("/api/company/");
    return transformCompany(response.data);
  },

  async getContractWithCompanies(limit: number, page: number): Promise<IContractsWithCompanyDataResponse> {
    const response = await apiAdmin.get<IRawContractsWithCompanyDataResponse>("/api/contract/", {
      params: {
        limit: limit,
        page: page
      }
    });

    return transformContractsWithCompany(response.data);
  },

  async createContract(data: any) {
    return await apiAdmin.post("/api/contract/", data);
  },

  async createContractOrder(data: any) {
    return await apiAdmin.post("/api/contract/order", data);
  },

  async updateUserTableRow(userID: number, data: any) {
    return await apiAdmin.patch(`/api/users/${userID}`, data);
  },

  async deleteUserAccount(userID: number) {
    return await apiAdmin.delete(`/api/users/${userID}`);
  },

  async getSoftwareVersions(limit: number, page: number, osID: number, softAppID: number): Promise<ISoftwareVersionResponse> {
    const response = await apiAdmin.get<IRawSoftwareVersionResponse>(`/api/software/`, {
      params: {
        limit: limit,
        page: page,
        os_id: osID,
        soft_app_id: softAppID
      }
    });

    return transformSoftwareVersions(response.data);
  },

  async deleteSoftwareVersionByID(softwareID: number) {
    return await apiAdmin.delete(`/api/software/${softwareID}`);
  },

  async updateSoftwareVersion(softwareID: number, data: any) {
    return await apiAdmin.patch(`/api/software/${softwareID}`, data);
  },

  async deleteCompany(companyID: number) {
    return await apiAdmin.delete(`/api/company/${companyID}`);
  },

  async updateCompanyTableRow(companyID: number, data: any) {
    return await apiAdmin.patch(`/api/company/${companyID}`, data);
  },

  async exportLicensesNumberByContractID(contractID: number) {
    return await apiAdmin.get(`/api/contract/licenses-number/${contractID}`);
  },

  async updateContractTableRow(contractID: number, data: any) {
    return await apiAdmin.patch(`/api/contract/${contractID}`, data);
  },

  async getContractNumbersByCompanyID(companyID: number): Promise<IContractNumbersByCompanyID[]> {
    const response = await apiAdmin.get<IRawContractNumbersByCompanyID[]>(`/api/contract/number/${companyID}`);
    return transformContractNumbersByCompanyID(response.data);
  },

  async getCompaniesAndContract(): Promise<ICompaniesAndContract> {
    const response = await apiAdmin.get<IRawCompaniesAndContract>("/api/company/contract/numbers");
    return transformCompaniesAndContract(response.data);
  },

  async getLicenses(contractID: number, page: number, limit: number): Promise<ApiLicenseWithUserResponse> {
    const response = await apiAdmin.get<IRawApiResponse>(`/api/license/`, {
      params: {
        page: page,
        limit: limit,
        id: contractID
      }
    });

    return transformLicenses(response.data);
  },

  async getLicenseByNumber(number: string): Promise<ILicenseInfo> {
    const response = await apiAdmin.get<IRawLicenseInfo>(`/api/license/${number}`);
    return transformLicenseInfo(response.data);
  },

  async getUserByID(id: number): Promise<IUser> {
    const response = await apiAdmin.get(`/api/users/${id}`);
    return response.data;
  },

  async repeatRegistrationEmail(email: string) {
    return await apiAdmin.post(`/api/users/repeat-email`, { email: email });
  },

  async getContractByCompanyID(companyID: number, limit: number, page: number): Promise<IContractWithUserAndCompanyByCompanyIDResponse> {
    const response =
      await apiAdmin.get<IRawContractWithUserAndCompanyByCompanyIDResponse>(`/api/contract/${companyID}`, {
        params: {
          limit: limit,
          page: page
        }
      });
    return transformContractsByCompanyID(response.data);
  },

  async getAudit(limit: number, page: number, startTime: string, endTime: string, selectedTable: string): Promise<IAuditLogEntryResponse> {
    const response = await apiAdmin.get<IRawAuditLogEntryResponse>("/api/audit/", {
      params: {
        limit: limit,
        page: page,
        startTime: startTime,
        endTime: endTime,
        tableName: selectedTable
      }
    });

    return transformAudit(response.data);
  },

  async getAuditTables(): Promise<string[]> {
    const response = await apiAdmin.get<string[]>("/api/audit/table");
    return response.data;
  },

  async sendSoftwareNotification() {
    return await apiAdmin.get("/api/software/notification");
  },

  async getSoftwareVersionInfoBySoftwareID(softwareID: number): Promise<ISoftwareVersionBySoftwareID> {
    const response = await apiAdmin.get<IRawSoftwareVersionBySoftwareID>(`/api/software/${softwareID}`);
    return transformSoftwareVersionsBySoftwareID(response.data);
  }

};
