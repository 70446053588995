import { FC, useContext, useEffect, useId, useState } from "react";
import { Link } from "react-router-dom";
import {
  ACCESS_LEVEL_ADMIN,
  getUserInfoPageLinkByID,
  STATUS_CREATED,
  USERS_CREATE_PAGE_LINK,
  USERS_PAGE_LINK
} from "../../../core/constants";
import { formatDateTime } from "../../../utils/date";
import { adminService } from "../../../services/adminService";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Row from "../../../components/Wrapper/Row";
import PaginationView from "../../../components/Pagination/PaginationView";
import { adminUsersInitialState, IAdminUserData } from "../../../types/IAdmin";
import { roleService } from "../../../services/roleService";
import { IRole, roleInitialState } from "../../../types/IRole";
import { companiesInitialState, ICompanyResponse } from "../../../types/ICompany";
import PencilSquare from "../../../components/Icons/PencilSquare";
import TrashIcon from "../../../components/Icons/TrashIcon";
import AuthContext from "../../../context/AuthContext";
import useToast from "../../../hooks/useToast";
import PageNavigate from "../../../components/Navigate/PageNavigate";
import TableViewItem from "../../../components/Table/TableViewItem";
import TableSearchItem from "../../../components/Table/TableSearchItem";
import AdminAccessWrapper from "../../../permission/AccessControlWrapper";

const UsersPage: FC = () => {
    const id = useId();
    const { user } = useContext(AuthContext);
    const userRole = user.accessLevel;
    const { addToast } = useToast();

    const [itemViewCount, setItemViewCount] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const [tableData, setTableData] =
      useState<IAdminUserData[]>([adminUsersInitialState]);
    const [oldTableData, setOldTableData] =
      useState<IAdminUserData[]>([adminUsersInitialState]);

    const [itemCount, setItemCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [isUserExist, setIsUserExist] = useState(false);

    const [roles, setRoles] = useState<IRole[]>([roleInitialState]);
    const [companies, setCompanies] = useState<ICompanyResponse[]>(companiesInitialState);

    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [rowIDToEdit, setRowIDToEdit] = useState<number | undefined>(undefined);
    const [isFirstEditRowID, setIsFirstEditRowID] = useState<number>(-1);

    const getRowNumber = (index: number) => (currentPage - 1) * itemViewCount + index + 1;


    const filteredTableData = tableData.filter((val) => {
      return val.email.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
    });

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await adminService.getUsers(itemViewCount, currentPage);
          if (response.data !== null) {
            setTableData(response.data);
            setItemCount(response.count);
            setLastPage(response.lastPage);

            setOldTableData(response.data);

            setIsUserExist(true);
          }
        } catch (e) {
          if (e.response.status >= 400 && e.response.status < 500) {
            addToast(e.response.data.message, "warning");
          } else if (e.response.status >= 500) {
            addToast(e.response.data.message, "error");
          }
        }
      };
      fetchData();
    }, [itemViewCount, currentPage]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          if (userRole >= ACCESS_LEVEL_ADMIN) {
            const rolesResponse = await roleService.getAll();
            setRoles(rolesResponse);
            const res = await adminService.getCompany();
            if (res) {
              setCompanies(res.data);
            }
          }
        } catch (e) {
          if (e.response.status >= 400 && e.response.status < 500) {
            addToast(e.response.data.message, "warning");
          } else if (e.response.status >= 500) {
            addToast(e.response.data.message, "error");
          }
        }
      };

      if (userRole >= ACCESS_LEVEL_ADMIN) {
        fetchData();
      }
    }, []);


    const UserTableHead: FC = () => {
      return (
        <tr>
          <th className="text-center">№</th>
          <AdminAccessWrapper role={ACCESS_LEVEL_ADMIN}>
            <th className="text-center">Действие</th>
          </AdminAccessWrapper>
          <th className="text-center">Компания</th>
          <th className="text-center">Email сотрудника</th>
          <th className="text-center">Роль пользователя</th>
          <th className="text-center">Статус аккаунта</th>
          <th className="text-center">Дата последнего посещения</th>
          <th className="text-center">Дата создания аккаунта</th>
        </tr>
      );
    };

    useEffect(() => {
      if (rowIDToEdit !== isFirstEditRowID) {
        setIsFirstEditRowID(rowIDToEdit);
        setTableData(oldTableData);
      }
    }, [rowIDToEdit]);

    const handleEditButtonChange = (rowID: number) => {
      setIsEditMode(true);
      setRowIDToEdit(rowID);
    };

    const handleCloseEditButtonChange = (rowID: number) => {
      setIsEditMode(false);
      setTableData(oldTableData);
    };

    const handleRemoveRowChange = async (rowID: number) => {
      const confirm = window.confirm(`Вы уверены, что хотите удалить пользователя с id = ${rowID}`);
      if (confirm) {
        const confirm2 = window.confirm(`Вы точно уверены, что хотите удалить пользователя с id = ${rowID}`);
        if (confirm2) {
          try {
            const response = await adminService.deleteUserAccount(rowID);
            if (response.status == STATUS_CREATED) {
              setTableData(prevState => prevState.filter(row => row.ID !== rowID));
              setOldTableData(prevState => prevState.filter(row => row.ID !== rowID));
              addToast(response.data.message, "success");
            }
          } catch (e) {
            setTableData(oldTableData);
            if (e.response.status >= 400 && e.response.status < 500) {
              addToast(e.response.data.message, "warning");
            } else if (e.response.status >= 500) {
              addToast(e.response.data.message, "error");
            }
          }

        }
      }
    };

    const handleOnChangeField = (e: any, rowID: number) => {
      let value: string;

      const { name: fieldName } = e.target;

      if (fieldName === "companyName" || fieldName === "roleName") {
        value = e.target.options[e.target.selectedIndex].text;
      } else if (fieldName === "isBanned") {
        value = e.target.checked;
      } else {
        value = e.target.value;
      }

      setTableData(prevData =>
        prevData.map(row =>
          row.ID === rowID
            ? { ...row, [fieldName]: value }
            : row
        )
      );
    };

    const compareRows = (originalRow, updatedRow) => {
      interface IChanges {
        companyName?: string;
        roleName?: string;
        email?: string;
        isBanned?: boolean;
      }

      const changes: IChanges = {};

      for (const key in originalRow) {
        if (originalRow[key] !== updatedRow[key]) {
          changes[key] = updatedRow[key];
        }
      }

      return changes;
    };

    const handleSaveRowChanges = async () => {
      setIsEditMode(false);

      const row = tableData.find(row => row.ID === rowIDToEdit);
      const oldRow = oldTableData.find(row => row.ID === rowIDToEdit);
      const changedFields = compareRows(oldRow, row);
      const formData = {};

      const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

      if (changedFields.companyName) {
        const companyID = companies.find(row => row.name === changedFields.companyName).ID;
        if (companyID === 0) {
          Object.assign(formData, { isCompanyID: true });
        }
        Object.assign(formData, { companyID: companyID });
      }

      if (changedFields.roleName) {
        const roleID = roles.find(row => row.name === changedFields.roleName).ID;
        Object.assign(formData, { roleID: roleID });
      }

      if (changedFields.email) {
        if (isValidEmail(changedFields.email)) {
          Object.assign(formData, { email: changedFields.email });
        } else {
          setTableData(oldTableData);
          return;
        }
      }

      if (changedFields.hasOwnProperty("isBanned")) {
        Object.assign(formData, { isBanned: changedFields.isBanned });
      }

      if (!(Object.entries(formData).length === 0)) {
        try {
          const response = await adminService.updateUserTableRow(rowIDToEdit, formData);
          if (response.status == STATUS_CREATED) {
            setOldTableData(tableData);
            addToast(response.data.message, "success");
          }
        } catch (e) {
          setTableData(oldTableData);
          if (e.response.status >= 400 && e.response.status < 500) {
            addToast(e.response.data.message, "warning");
          } else if (e.response.status >= 500) {
            addToast(e.response.data.message, "error");
          }
        }
      }
    };


    return (
      <Card>
        <CardHeader>
          <h6 className="mt-2">Пользователи</h6>
        </CardHeader>
        <AdminAccessWrapper>
          <PageNavigate
            current={USERS_PAGE_LINK}
            to={USERS_CREATE_PAGE_LINK}
            title={"Добавить"}
          />
        </AdminAccessWrapper>
        {isUserExist ? (
          <CardBody>
            <Row>
              <TableViewItem
                itemViewCount={itemViewCount}
                setItemViewCount={setItemViewCount}
              />
              <TableSearchItem
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </Row>
            <div className="table-responsive">
              <table className="table table-striped table-bordered my-0">
                <thead>
                <UserTableHead />
                </thead>
                <tbody>
                {filteredTableData.map((row, key: number) => (
                  <tr key={row.ID}>
                    <td className={"align-middle text-center"}>{getRowNumber(key)}</td>


                    <AdminAccessWrapper>
                      <td className={"align-middle text-center"}>
                        {isEditMode && rowIDToEdit === row.ID ? (
                          <>
                            <button style={{ all: "unset" }} onClick={handleSaveRowChanges}>
                              <i className="fs-5 bi bi-save me-2" />
                            </button>
                            <button style={{ all: "unset" }} onClick={() => handleCloseEditButtonChange(row.ID)}>
                              <PencilSquare className="fs-5 text-danger" />
                            </button>
                          </>
                        ) : (
                          <>
                            <button style={{ all: "unset" }} onClick={() => handleEditButtonChange(row.ID)}>
                              <i className="fs-5 bi bi-pencil-square me-2" />
                            </button>
                            <button style={{ all: "unset" }} onClick={() => handleRemoveRowChange(row.ID)}>
                              <TrashIcon className="fs-5 me-2" />
                            </button>
                          </>
                        )}
                      </td>
                    </AdminAccessWrapper>

                    {isEditMode && rowIDToEdit === row.ID ? (
                      <td className={"align-middle"}>
                        <select
                          className="d-inline-block form-select form-select-sm"
                          name="companyName"
                          onChange={(e) => handleOnChangeField(e, row.ID)}
                          defaultValue={companies.find(r => r.name === row.companyName).ID}
                        >
                          {companies.map((item, key) => (
                            <option key={item.ID} value={item.ID}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </td>
                    ) : (
                      <td className="align-middle text-center">{row.companyName}</td>
                    )}


                    {isEditMode && rowIDToEdit === row.ID ? (
                      <td className={"align-middle"}>
                        <input
                          type="text"
                          id={`email-${id}`}
                          className="form-control form-control-sm"
                          placeholder="email"
                          name="email"
                          onChange={(e) => handleOnChangeField(e, row.ID)}
                          value={row.email}
                        />
                      </td>
                    ) : (
                      <td className="align-middle text-center">
                        <Link to={getUserInfoPageLinkByID(row.ID)}>
                          {row.email}
                        </Link>
                      </td>
                    )}


                    {isEditMode && rowIDToEdit === row.ID ? (
                      <td className={"align-middle"}>
                        <select
                          className="d-inline-block form-select form-select-sm"
                          name="roleName"
                          onChange={(e) => handleOnChangeField(e, row.ID)}
                          defaultValue={roles.find(r => r.name === row.roleName).ID}
                        >
                          {roles.map((item, key) => (
                            <option key={item.ID} value={item.ID}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </td>
                    ) : (
                      <td className="align-middle text-center">{row.roleName}</td>
                    )}


                    {isEditMode && rowIDToEdit === row.ID ? (
                      <td className={"align-middle"}>
                        <div className="col-12 d-flex">
                          <label htmlFor={`is-upload-error-${id}`} className="me-2">
                            Заблокирован
                          </label>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              name="isBanned"
                              id={`is-upload-error-${id}`}
                              onChange={(e) => handleOnChangeField(e, row.ID)}
                              checked={row.isBanned}
                            />
                          </div>
                        </div>
                      </td>
                    ) : (
                      <td className={"align-middle"}>
                        <div
                          className={`alert ${
                            row.isBanned ? "alert-danger" : "alert-success"
                          }  text-center p-0 m-0`}>
                          {row.isBanned ? "Заблокирован" : "Активен"}
                        </div>
                      </td>
                    )}

                    <td className="align-middle text-center">
                      {row.lastVisitedAt ? formatDateTime(row.lastVisitedAt) : "Нет посещений"}
                    </td>
                    <td className="align-middle text-center">
                      {formatDateTime(row.createdAt)}
                    </td>


                  </tr>
                ))}
                </tbody>
                <tfoot>
                <UserTableHead />
                </tfoot>
              </table>
            </div>
            <Row>
              <PaginationView
                currentPage={currentPage}
                lastPage={lastPage}
                itemViewCount={itemViewCount}
                itemCount={itemCount}
                setCurrentPage={setCurrentPage}
              />
            </Row>
          </CardBody>
        ) : (
          <CardBody>
            <div>Пользователи отсутствуют!</div>
            <Link to={USERS_CREATE_PAGE_LINK}>Создайте аккаунт пользователя</Link>
          </CardBody>
        )}
      </Card>
    );
  }
;

export default UsersPage;
