import { v4 as uuidv4 } from 'uuid';

export const extractFileName = (url: string) => {
    const urlObject = new URL(url);
    const pathArray = urlObject.pathname.split('/');
    return pathArray[pathArray.length - 1];

}

export const generateRequestID = () => {
    return uuidv4();
}

export const bytesToMB = (bytes) => {
    if (isNaN(bytes) || bytes < 0) {
        throw new Error("Invalid input: bytes must be a non-negative number");
    }

    const MB = bytes / (1024 * 1024);
    return MB.toFixed(2); // Округляем до двух знаков после запятой
}