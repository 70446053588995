import {ReactNode, Suspense} from "react";
import { Navigate } from "react-router-dom";
import useSession from "../hooks/useSession";

type Props = {
    children: ReactNode
}

const PublicRoute = (props: Props) => {
    const {children} = props;

    const {isAuth} = useSession();

    if (isAuth) {
        return <Navigate to="/"/>
    }

    return (
        <Suspense>{children}</Suspense>
    );
}

export default PublicRoute;