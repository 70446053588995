import { ChangeEvent, Dispatch, FC } from "react";
import { licenseService } from "../../services/licenseService";

import styles from "./Table/Table.module.scss";
import { ILicense } from "../../types/ILicenses";
import cn from "classnames";
import useTheme from "../../hooks/useThemes";
import useToast from "../../hooks/useToast";

interface UploadFileProps {
  contractNumber: number;
  data: ILicense[];
  setData: Dispatch<ILicense[]>;
  licenseNumber: string;
}

const UploadSingleFile: FC<UploadFileProps> = ({ contractNumber, data, setData, licenseNumber }) => {
  const { theme } = useTheme();
  const { addToast } = useToast();

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const formData = new FormData();
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf(".");
      if (lastDotIndex !== -1) {
        const fileExt = fileName
          .substring(lastDotIndex + 1)
          .toLocaleLowerCase();

        formData.append("file", file);
        formData.append("contractId", contractNumber.toString());
        formData.append("licenseNumber", licenseNumber);

        if (fileExt === "ccb") {
          try {
            const response = await licenseService.uploadSingleFile(formData);
            if (response) {
              const updatedState = data.map((item: ILicense) => {
                if (item.ID === response.ID) {
                  return {
                    ...item,
                    ...item,
                    ccbOldFileName: response.oldCcbFileName,
                    ccbFileName: response.urlCcbFile,
                    licFileName: response.urlLicFile
                  };
                }
                return item;
              });
              setData(updatedState);
            }

          } catch (e: any) {
            if (e.response.status >= 400 && e.response.status < 500) {
              addToast(e.response.data.message, "warning");
            } else if (e.response.status >= 500) {
              addToast(e.response.data.message, "error");
            }
          }
        }
      }
    } else {
    }
  };

  return (
    <>
      <input
        type="file"
        // className={styles.file}
        className={cn({
          [styles.link_button_d]: theme === "dark",
          [styles.link_button_l]: theme === "light"
        }, styles.file)}
        accept=".ccb"
        onChange={handleUpload}
        style={{ width: "100%" }}
      />
    </>
  );
};

export default UploadSingleFile;
