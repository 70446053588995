import { FC, useEffect, useState } from "react";
import useToast from "../../../hooks/useToast";
import { adminService } from "../../../services/adminService";
import { Link, useParams } from "react-router-dom";
import { getContractLicensesPageLinkByContractID, getUserInfoPageLinkByID } from "../../../core/constants";
import {
  contractWithUserAndCompanyByCompanyIDInitialState,
  IContractWithUserAndCompanyByCompanyID,
  IContractWithUserAndCompanyByCompanyIDResponse
} from "../../../types/IContract";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import TableSearchItem from "../../../components/Table/TableSearchItem";
import TableViewItem from "../../../components/Table/TableViewItem";
import Row from "../../../components/Wrapper/Row";
import PaginationView from "../../../components/Pagination/PaginationView";
import PencilSquare from "../../../components/Icons/PencilSquare";
import { formatDateSimple, formatDateTime } from "../../../utils/date";
import AdminAccessWrapper from "../../../permission/AccessControlWrapper";

const CompanyContractsPage: FC = () => {
  const { id } = useParams();
  const { addToast } = useToast();


  const [tableData, setTableData] =
    useState<IContractWithUserAndCompanyByCompanyID[]>([contractWithUserAndCompanyByCompanyIDInitialState]);
  const [searchValue, setSearchValue] = useState("");

  const [itemCount, setItemCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [itemViewCount, setItemViewCount] = useState(10);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [rowIDToEdit, setRowIDToEdit] = useState<number | undefined>(undefined);
  const [editedRow, setEditedRow] = useState<object | undefined>();
  const [isFirstEditRowID, setIsFirstEditRowID] = useState<number>(-1);


  const getRowNumber = (index: number) => (currentPage - 1) * itemViewCount + index + 1;


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: IContractWithUserAndCompanyByCompanyIDResponse = await adminService.getContractByCompanyID(Number(id), itemViewCount, currentPage);
        if (response) {
          setTableData(response.data);
          setItemCount(response.count);
          setLastPage(response.lastPage);
        }
      } catch (e) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };
    fetchData();
  }, [itemViewCount, currentPage]);

  const ContractTableHead: FC = () => {
    return (
      <tr className="text-center">
        <th>id</th>
        <AdminAccessWrapper>
          <th className="text-center">Действие</th>
        </AdminAccessWrapper>
        <th className="text-center">Номер контракта</th>
        <th className="text-center">Дата заключения</th>
        <th className="text-center">Количество лицензий</th>
        <th className="text-center">Осталось лицензий</th>
        <th className="text-center">Гарантийные обязательства</th>
        <th className="text-center">Экспорт номеров</th>
        <th className="text-center">Контракт создал</th>
        <th className="text-center">Дата заключения контракта</th>
        <th className="text-center" style={{ width: "200px" }}>Дата создания</th>
      </tr>
    );
  };

  const filteredTableData: IContractWithUserAndCompanyByCompanyID[] = tableData.filter((val: IContractWithUserAndCompanyByCompanyID) => {
    return tableData;//val.CompanyName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
  });

  const handleButtonExport = async (contractID: number) => {
    try {
      const response = await adminService.exportLicensesNumberByContractID(contractID);
      const contentDisposition = response.headers["Content-Disposition"];
      let fileName = "license_numbers_contract_id_" + String(contractID);

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }
  };


  return (
    <Card>
      <CardHeader>
        <h6 className="mt-2">Компания: {tableData[0].CompanyName}</h6>
      </CardHeader>
      <CardBody>
        <Row>
          <TableViewItem
            itemViewCount={itemViewCount}
            setItemViewCount={setItemViewCount}
          />
          <TableSearchItem
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </Row>
        <div
          className="table-responsive table mt-2"
          id="dataTable"
          role="grid"
          aria-describedby="dataTable_info">
          <table className="table table-striped table-bordered my-0" id="dataTable">
            <thead>
            <ContractTableHead />
            </thead>
            <tbody>
            {filteredTableData.map((row: IContractWithUserAndCompanyByCompanyID, key: number) => (
              <tr key={row.ContractID}>
                <td className="align-middle text-center">
                  {getRowNumber(key)}
                </td>

                <AdminAccessWrapper>
                  <td className="align-middle text-center">
                    {isEditMode && rowIDToEdit === row.ContractID ? (
                      <>
                        {/*<button style={{ all: "unset" }} onClick={handleSaveRowChanges}>*/}
                        <i className="fs-5 bi bi-save me-2" />
                        {/*</button>*/}
                        {/*<button style={{ all: "unset" }}*/}
                        {/*        onClick={() => handleCloseEditButtonChange(row.contractID)}>*/}
                        <PencilSquare className="fs-5 text-danger" />
                        {/*</button>*/}
                      </>
                    ) : (
                      <>
                        {/*<button style={{ all: "unset" }} onClick={() => handleEditButtonChange(row.contractID)}>*/}
                        <i className="fs-5 bi bi-pencil-square me-2" />
                        {/*</button>*/}
                      </>
                    )}
                  </td>
                </AdminAccessWrapper>

                <td className="align-middle text-center">
                  <Link to={getContractLicensesPageLinkByContractID(row.ContractID)}>
                    {row.ContractNumber}
                  </Link>
                </td>
                <td className="align-middle text-center">{formatDateSimple(row.DateOfConclusion)}</td>
                <td className="align-middle text-center">{row.LicCount}</td>
                <td className="align-middle text-center">{row.LicAvailable}</td>
                <td className="align-middle text-center">{row.WarrantyExist ? "Да" : "Нет"}</td>
                <td className="align-middle text-center" style={{ width: "100px" }}>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleButtonExport(row.ContractID)}
                  >
                    Скачать
                  </button>
                </td>
                <td className="align-middle text-center">
                  {row.UserName ? (
                    <Link to={getUserInfoPageLinkByID(row.UserID)}>
                      {row.UserName + " " + row.UserSurname}
                    </Link>
                  ) : ("Отсутствует")}

                </td>
                <td className="align-middle text-center">{formatDateSimple(row.DateOfConclusion)}</td>
                <td className="align-middle text-center">{formatDateTime(row.CreatedAt)}</td>

              </tr>
            ))}
            </tbody>
            <tfoot>
            <ContractTableHead />
            </tfoot>
          </table>
        </div>
        <Row>
          <PaginationView
            currentPage={currentPage}
            lastPage={lastPage}
            itemViewCount={itemViewCount}
            itemCount={itemCount}
            setCurrentPage={setCurrentPage}
          />
        </Row>
      </CardBody>
    </Card>
  );
};

export default CompanyContractsPage;