import { ChangeEvent, Dispatch, FC, SetStateAction, useId } from "react";

interface DateOfConclusionContractInputProps {
  dateOfConclusion: string;
  setDateOfConclusion: Dispatch<SetStateAction<string>>;
  isContractOrder?: boolean;
}

const DateOfConclusionContractInput: FC<DateOfConclusionContractInputProps> = ({
                                                                                 dateOfConclusion,
                                                                                 setDateOfConclusion,
                                                                                 isContractOrder
                                                                               }) => {
  const id = useId();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDateOfConclusion(e.target.value);
  };

  return (
    <div className="col-12">
      <label htmlFor={`dateOfConclusion-${id}`}>
        {isContractOrder ? "Дата заключения заявки" : "Дата заключения контракта"}
      </label>
      <div className="input-group">
        <div className="input-group-text">
          <i className="bi bi-calendar-date"></i>
        </div>
        <input
          type="date"
          id={`dateOfConclusion-${id}`}
          className="form-control"
          name="date_of_conclusion"
          value={dateOfConclusion}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default DateOfConclusionContractInput;