import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useId, useRef, useState } from "react";
import { adminService } from "../../services/adminService";
import { STATUS_CREATED } from "../../core/constants";
import useToast from "../../hooks/useToast";

interface UploadProgramInputProps {
  isFileUploaded: boolean;
  setIsFileUploaded: Dispatch<SetStateAction<boolean>>;
  setUploadedFileID: Dispatch<SetStateAction<number>>;
  isFileResetForm: boolean;
  setIsFileResetForm: Dispatch<SetStateAction<boolean>>;
}


const UploadProgramInput: FC<UploadProgramInputProps> = ({
                                                           isFileUploaded,
                                                           setIsFileUploaded,
                                                           setUploadedFileID,
                                                           isFileResetForm,
                                                           setIsFileResetForm
                                                         }) => {
    const id = useId();
    const { addToast } = useToast();

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [isFileLoaded, setIsFileLoaded] = useState<boolean>(false);
    const [fileSize, setFileSize] = useState<number>(0);
    const [isUploadProcess, setIsUploadProcess] = useState<boolean>(false);
    const fileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (isFileResetForm) {
        setIsFileResetForm(false);
        setSelectedFile(null);
        setFileSize(0);
        setIsUploadProcess(false);
        setIsFileLoaded(false);
        setUploadProgress(0);
        if (fileRef.current) {
          fileRef.current.value = "";
        }
      }
    }, [isFileResetForm]);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files && e.target.files[0];
      setSelectedFile(file);
      setIsFileLoaded(true);

      const fileSizeInKB = (file.size / 1024).toFixed(0);
      setFileSize(Number(fileSizeInKB));
    };


    const handleUpload = async (e: any) => {
      e.preventDefault();

      if (selectedFile) {
        const file = selectedFile;//fileInput.files[0];
        const chunkSize = 1024 * 1024 * 4; // 4 MB chunks

        const totalChunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;
        let startTime = Date.now();
        setIsUploadProcess(true);
        setUploadProgress(0);
        setIsFileUploaded(false);

        while (currentChunk < totalChunks) {
          const start = currentChunk * chunkSize;
          const end = Math.min(start + chunkSize, file.size);
          const chunk = file.slice(start, end);

          const formData = new FormData();
          formData.append("chunk", chunk);
          formData.append("totalChunks", String(totalChunks));
          formData.append("currentChunk", String(currentChunk));
          formData.append("fileName", file.name);

          try {
            const response = await adminService.uploadFile(formData);
            if (response.data) {
              const uploadProgress = (((currentChunk + 1) / totalChunks) * 100).toFixed(0);
              setUploadProgress(Number(uploadProgress));

              if (response.status == STATUS_CREATED) {
                setIsFileUploaded(true);
                setUploadProgress(100);
                console.log(uploadProgress);
                setUploadedFileID(Number(response.data.id));
                addToast(response.data.message, "success");
              }
            }
          } catch (e) {
            if (e.response.status >= 400 && e.response.status < 500) {
              addToast(e.response.data.message, "warning");
            } else if (e.response.status >= 500) {
              addToast(e.response.data.message, "error");
            }
            break;
          }

          currentChunk++;
        }

        let lastTime = Date.now();

        console.log("Время загрузки файла: ", (lastTime - startTime));
      }
    };


    return (
      <div className="col-12">
        <label htmlFor={`file-upload-${id}`}>Файл</label>
        <div className="input-group">
          <input
            type="file"
            className="form-control"
            id={`file-upload-${id}`}
            aria-describedby="inputGroupFileAddon04"
            aria-label="Upload"
            onChange={handleFileChange}
            ref={fileRef}

          />
          <button
            className="btn btn-primary"
            type="submit"
            onClick={handleUpload}
          >
            Загрузить
          </button>
        </div>
        {isFileLoaded && (
          <div className="mt-3">
            <p className="d-inline mt-3 me-3">Размер файла: {fileSize} КБ</p>

            {isUploadProcess && (
              <div className="d-flex mt-3 align-items-center">
                <p className="m-0 me-3" style={{ whiteSpace: "nowrap" }}>Статус загрузки: </p>
                {!isFileUploaded ? (
                  <div className="progress" style={{ width: "100%" }}>
                    <div className="progress-bar" style={{ width: `${uploadProgress}%` }}>{uploadProgress}%</div>
                  </div>
                ) : (
                  <div>файл загружен</div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    )
      ;
  }
;

export default UploadProgramInput;