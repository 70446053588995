import { Dispatch, FC, useId } from "react";
import MDEditor from "@uiw/react-md-editor";
import useTheme from "../../hooks/useThemes";

interface DescriptionInputProps {
  description: string;
  setDescription: Dispatch<string>;
}


const DescriptionInput: FC<DescriptionInputProps> = ({ description, setDescription }) => {
  const id = useId();
  const { theme } = useTheme();

  return (
    <div className="col-12">
      <label htmlFor={id}>Описание версии</label>
      <div className="form-floating">
        <MDEditor
          id={id}
          value={description}
          onChange={setDescription}
          data-color-mode={theme === "light" ? "light" : "dark"}
          className="mt-1"
        />
      </div>
    </div>
  );
};

export default DescriptionInput;