import { FC } from "react";

interface LicenseNumberRowProps {
  licenseNumber: string;
}

const LicenseNumberRow: FC<LicenseNumberRowProps> = ({ licenseNumber }) => {
  return (
    <td style={{ width: "180px", verticalAlign: "middle", textAlign: "center" }}>
      {licenseNumber}
    </td>
  );
};

export default LicenseNumberRow;