import { FC, useEffect, useState } from "react";
import MDEditor from "@uiw/react-md-editor";

import CardBody from "../../../components/Card/CardBody";
import Container from "../../../components/Wrapper/Container";
import Row from "../../../components/Wrapper/Row";
import Card from "../../../components/Card/Card";
import { userService } from "../../../services/userService";
import CardHeader from "../../../components/Card/CardHeader";
import { getDate } from "../../../utils/date";
import { bytesToMB } from "../../../utils/utils";
import { ISoftwareVersion, softwareVersionInitialState } from "../../../types/ISoftware";
import SoftwareSelect from "../../../module/UploadPage/SoftwareSelect";
import ProgramSelect from "../../../module/UploadPage/ProgramSelect";
import { FILE_SERVER_URL } from "../../../core/constants";
import Col from "../../../components/Wrapper/Col/Col";
import useToast from "../../../hooks/useToast";
import useTheme from "../../../hooks/useThemes";
import cn from "classnames";
import styles from "../../../styles/MarkDown.module.scss";


const DownloadPage: FC = () => {
  const { addToast } = useToast();
  const { theme } = useTheme();


  const [softwareID, setSoftwareID] = useState<number>(1);
  const [softAppID, setSoftAppID] = useState<number>(0);
  const [data, setData] = useState<ISoftwareVersion[] | null>([softwareVersionInitialState]);
  const [selectedVersion, setSelectedVersion] = useState<string>("");
  const [selectedOS, setSelectedOS] = useState<string>("");

  const fetchData = async () => {
    try {
      const response = await userService.getSoftwareVersions(1, softAppID);
      setData(response);

    } catch (e) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [softwareID, softAppID]);

  const filteredData = data.filter(item => {
    return (
      (selectedVersion ? item.version === selectedVersion : true) &&
      (selectedOS ? item.operationSystemName === selectedOS : true)
    );
  });

  const handleDownloadClick = async (fileName: string) => {
    try {
      const response = await userService.generateShortLink(fileName);
      if (response) {
        const link = document.createElement("a");
        link.href = FILE_SERVER_URL + response.data.downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (e) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }
  };

  return (
    <Card className="mt-3">
      <CardBody>
        <Container>
          <Row className="p-1">
            <Card className="p-0">
              <CardHeader>
                Фильтры
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <SoftwareSelect
                      isLoadingMode={false}
                      softwareID={softwareID}
                      setSoftwareID={setSoftwareID}
                    />
                  </Col>
                  <Col>
                    <ProgramSelect
                      isLoadingMode={false}
                      softAppID={softAppID}
                      setSoftAppID={setSoftAppID}
                    />
                  </Col>
                  <Col>
                    <div className="col-12">
                      <label htmlFor="version">Версия</label>
                      <div className="input-group">
                        <select
                          id="version"
                          className="form-select"
                          name="version"
                          onChange={(e) => setSelectedVersion(e.target.value)}
                        >
                          <option value="">Все версии</option>
                          {data
                            .map((item: ISoftwareVersion) => item.version)
                            .filter((version, index, self) => self.indexOf(version) === index)
                            .map((version, key) => (
                              <option key={key} value={version}>{version}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="col-12">
                      <label htmlFor="op_sys_id">Операционная система</label>
                      <div className="input-group">
                        <select
                          id="op_sys_id"
                          className="form-select"
                          name="op_sys_id"
                          onChange={(e) => setSelectedOS(e.target.value)}
                        >
                          <option selected value="">Все ОС</option>
                          <option value="Windows">Windows</option>
                          <option value="Linux">Linux</option>
                          <option value="Virtual Machine">Virtual Machine</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Row className="mt-3">
            {filteredData.length > 0 ? (
              filteredData.map((item: ISoftwareVersion, key: number) => (
                <div className="col-lg-4 mb-4 p-1" key={key}>
                  <Card className="p-0" style={{ height: "650px" }}>
                    <CardHeader
                      className="">{item.name} ({item.isFullRelease ? "Полный" : "неполный"} релиз)</CardHeader>
                    <CardBody className="d-flex flex-column justify-content-between">
                      <div>
                        <p><b>Версия: </b>{item.version}</p>
                        <p><b>Операционная система: </b>{item.operationSystemName}</p>
                        <p><b>Дата релиза: </b>{getDate(item.releaseDate)}</p>
                        <div style={{ overflow: "auto", maxHeight: "350px" }}>
                          <b>Описание:</b>
                          <br />
                          <MDEditor.Markdown
                            className={cn(theme === "light" ? styles.root_light : styles.root_dark)}
                            source={item.description}
                            style={{
                              backgroundColor: theme === "light" ? "white" : "#212529",
                              color: theme === "light" ? "black" : "white" // Черный текст
                            }}
                          />

                          <b>Список изменений:</b>
                          <br />
                          <MDEditor.Markdown
                            source={item.changeLog}
                            className={cn(theme === "light" ? styles.root_light : styles.root_dark)}
                            style={{
                              backgroundColor: theme === "light" ? "white" : "#212529",
                              color: theme === "light" ? "black" : "white" // Черный текст
                            }}
                          />
                          <p className="mt-2"><b>Размер файла: </b>{bytesToMB(item.fileSize)} Мб</p>

                        </div>
                      </div>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleDownloadClick(item.fileName)}
                      >
                        Скачать
                      </button>
                    </CardBody>
                  </Card>
                </div>
              ))
            ) : (
              <div>Программное обеспечение не найдено</div>
            )}
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};

export default DownloadPage;
