import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import UploadSingleFile from '../../UploadSingleFile';
import cn from 'classnames';
import useTheme from '../../../../hooks/useThemes';

import styles from '../Table.module.scss';

interface CcbFileRowProps {
  ccbFileName: string;
  handleDownloadClick: (licFileName: string) => void;
  contractID: number;
  data: any;
  setData: Dispatch<SetStateAction<any>>;
  licenseNumber: string;
}

const CcbFileRow: FC<CcbFileRowProps> = ({
                                           ccbFileName,
                                           handleDownloadClick,
                                           contractID,
                                           data,
                                           setData,
                                           licenseNumber
                                         }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <td className={'align-middle text-center'}>
        {ccbFileName ? (
          <div
            className={cn({
              [styles.link_button_d]: theme === 'dark',
              [styles.link_button_l]: theme === 'light'
            })}
            onClick={() => handleDownloadClick(ccbFileName)}
          >
            Скачать
          </div>
        ) : (
          <UploadSingleFile
            contractNumber={contractID}
            data={data}
            setData={setData}
            licenseNumber={licenseNumber}
          />

        )}
    </td>
  );
};

export default CcbFileRow;