import { FC, useEffect, useState } from "react";
import CardHeader from "../../../components/Card/CardHeader";
import PageNavigate from "../../../components/Navigate/PageNavigate";
import { CONTRACT_PAGE_LINK, getContractLicenseInfoPageLinkByContractNumber } from "../../../core/constants";
import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import { adminService } from "../../../services/adminService";
import { useParams } from "react-router-dom";
import useToast from "../../../hooks/useToast";
import { ILicenseInfo, licenseInfoInitialState } from "../../../types/ILicenses";
import { formatDateSimple, formatDateTime, getDate } from "../../../utils/date";
import Col from "../../../components/Wrapper/Col/Col";
import Row from "../../../components/Wrapper/Row";
import { userService } from "../../../services/userService";

const LicenseInfoPage: FC = () => {
  const { id, number } = useParams();
  const { addToast } = useToast();

  const [data, setData] =
    useState<ILicenseInfo>(licenseInfoInitialState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getLicenseByNumber(number);

        if (response) {
          setData(response);
        }
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    fetchData();
  }, []);

  const handleDownloadClickClick = async (downloadUrl: string) => {
    try {
      const response = await userService.handleDownloadClick(downloadUrl);
      const contentDisposition = response.headers["Content-Disposition"];
      let fileName = "file";

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      } else {
        const urlSegments = downloadUrl.split("/");
        fileName = urlSegments[urlSegments.length - 1];
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }

  };


  return (
    <Card>
      <CardHeader>
        <h6 className="mt-2">Номер лицензии: {data.LicenseNumber}</h6>
      </CardHeader>
      <PageNavigate
        current={getContractLicenseInfoPageLinkByContractNumber(Number(id), number)}
        to={CONTRACT_PAGE_LINK}
        title={"Вернуться"}
      />
      <CardBody>
        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="LicenseNumber">
                <strong>Номер</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="LicenseNumber"
                placeholder="LicenseNumber"
                name="LicenseNumber"
                value={data.LicenseNumber}
                disabled
              />
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="LicVersion">
                <strong>Версия</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="LicVersion"
                placeholder="LicVersion"
                name="LicVersion"
                value={data.LicVersion}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="CompanyName">
                <strong>Компания</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="CompanyName"
                placeholder="CompanyName"
                name="CompanyName"
                value={data.CompanyName}
                disabled
              />
            </div>
          </Col>

          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="ContractNumber">
                <strong>Номер контракта</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="ContractNumber"
                placeholder="ContractNumber"
                name="ContractNumber"
                value={data.ContractNumber}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="HwFix">
                <strong>Привязка оборудования</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="HwFix"
                placeholder="HwFix"
                name="HwFix"
                value={data.HwFix ? "Да" : "Нет"}
                disabled
              />
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="DateOfIssue">
                <strong>Дата заключения контракта</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="DateOfIssue"
                placeholder="DateOfIssue"
                name="DateOfIssue"
                value={data.DateOfIssue ? formatDateSimple(data.DateOfIssue) : "Отсутствует"}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="ExpirationDate">
                <strong>Срок лицензии</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="ExpirationDate"
                placeholder="ExpirationDate"
                name="ExpirationDate"
                value={getDate(data.ExpirationDate) === "31.12.9999"
                  ? "бессрочно" : formatDateSimple(data.ExpirationDate)}
                disabled
              />
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="IssueComplete">
                <strong>Выдана</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="IssueComplete"
                placeholder="IssueComplete"
                name="IssueComplete"
                value={data.IssueComplete ? "Да" : "Нет"}
                disabled
              />
            </div>
          </Col>
        </Row>


        {data.IssueComplete && (
          <>
            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="CcbOldFileName">
                    <strong>Имя файла конфигурации</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="CcbOldFileName"
                    placeholder="CcbOldFileName"
                    name="CcbOldFileName"
                    value={data.CcbOldFileName}
                    disabled
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="CcbFileName">
                    <strong>Имя файла после генерации</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="CcbFileName"
                    placeholder="CcbFileName"
                    name="CcbFileName"
                    value={data.CcbFileName}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="UserEmail">
                    <strong>Email пользователя</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="UserEmail"
                    placeholder="UserEmail"
                    name="UserEmail"
                    value={data.UserEmail}
                    disabled
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="UserName">
                    <strong>Лицензию загрузил</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="UserName"
                    placeholder="UserName"
                    name="UserName"
                    value={data.UserName + " " + data.UserSurname}
                    disabled
                  />
                </div>
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="LicFileName">
                <strong>Имя файла лицензии</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="LicFileName"
                placeholder="LicFileName"
                name="LicFileName"
                value={data.LicFileName}
                disabled
              />
            </div>
          </Col>

          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="IssueDate">
                <strong>Дата выдачи</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="IssueDate"
                placeholder="IssueDate"
                name="IssueDate"
                value={formatDateTime(data.IssueDate)}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="UpdatedAt">
                <strong>Дата обновления</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="UpdatedAt"
                placeholder="UpdatedAt"
                name="UpdatedAt"
                value={formatDateTime(data.UpdatedAt)}
                disabled
              />
            </div>
          </Col>

          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="CreatedAt">
                <strong>Дата создания</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="CreatedAt"
                placeholder="CreatedAt"
                name="CreatedAt"
                value={formatDateTime(data.CreatedAt)}
                disabled
              />
            </div>
          </Col>
        </Row>


        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="IssueComplete">
                <strong>Выдана</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="IssueComplete"
                placeholder="IssueComplete"
                name="IssueComplete"
                value={data.IssueComplete ? "Да" : "Нет"}
                disabled
              />
            </div>
          </Col>

          {data.Description && (
            <p><b>Описание: </b>{data.Description}</p>
          )}
        </Row>


        <Row>


          <Col>
            <Card>
              <CardHeader>Плагины</CardHeader>
              <CardBody>
                <div className="mb-3">
                  {Object.entries(data.Plugins).map(([item, isChecked], key) => (
                    <div key={key} className="input-group">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id={`plugin-${item}-${1}`}
                          name={item}
                          checked={isChecked}
                          disabled
                        />
                        <label className="form-check-label" htmlFor={`plugin-${item}-${1}`}>
                          {item}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col>
            <></>
          </Col>

        </Row>

        <Row className="mt-3">
          <Col>
            <button
              className="btn btn-primary"
              onClick={() => handleDownloadClickClick("")}
            >
              Скачать лицензию
            </button>
          </Col>
        </Row>


      </CardBody>
    </Card>
  );
};

export default LicenseInfoPage;