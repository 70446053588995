// TODO поменять на CamelCase, Посмотреть авторизацию
export interface IUser {
  id: number;
  email: string;
  name: string;
  surname: string;
  last_visited_at: string;
  is_banned: boolean;
  company_name: string;
  role: {
    id: number;
    access_level: number;
    name: string;
  };
  created_at: string;
}

export const userNewInitialState: IUser = {
  id: 0,
  email: "",
  name: "",
  surname: "",
  last_visited_at: String(new Date()),
  is_banned: false,
  company_name: "",
  role: {
    id: 0,
    access_level: 0,
    name: ""
  },
  created_at: String(new Date())
};

export interface IUserAuth {
  id: number;
  email: string;
  name: string;
  surname: string;
  accessLevel: number;
  companyID: number;
  companyName: string;
  roleName: string;
}

// TODO убрать ?
export const userInitialState: IUserAuth = {
  id: 0,
  email: "",
  name: "",
  surname: "",
  accessLevel: -1,
  companyID: -1,
  companyName: "",
  roleName: ""
};

export type SignInCredentials = {
  email: string;
  password: string;
}
