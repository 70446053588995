import { FC } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../Sidebar/Sidebar";
import Header from "../../Header/Header";


const AdminLayout: FC = () => {
  return (
    <div className="app">
      <div className="container-fluid p-0">
        <Header />
        <div className="d-flex flex-nowrap">
          <div className="bg-primary col p-0 m-0" style={{ maxWidth: "250px" }}>
            <Sidebar />
          </div>
          <div className="col w-auto" style={{ padding: 0, margin: 0 }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
