import { ChangeEvent, Dispatch, FC, SetStateAction, useId } from "react";

interface LicVersionComponentProps {
  licVersion: number;
  setLicVersion: Dispatch<SetStateAction<number>>;
}

const LicVersionInput: FC<LicVersionComponentProps> = ({ licVersion, setLicVersion }) => {
  const id = useId();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLicVersion(Number(e.target.value));
  };

  return (
    <div className="col-12">
      <label htmlFor={`livVersion-${id}`}>Версия лицензий</label>
      <div className="input-group">
        <div className="input-group-text">
          <i className="bi bi-lock-fill"></i>
        </div>
        <input
          type="number"
          id={`livVersion-${id}`}
          className="form-control"
          placeholder="Введите версию лицензии"
          name="lic_version"
          onChange={handleChange}
          value={licVersion}
        />
      </div>
    </div>
  );
};

export default LicVersionInput;