import { FC } from "react";

import cn from "classnames";
import styles from "./Pagination.module.scss";

import { DOTS, usePagination } from "../../hooks/usePagination";
import useTheme from "hooks/useThemes";

type PaginationProps = {
  itemPerPage?: number;
  currentPage: number;
  pageCount: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (page: number) => void;
};

const Pagination: FC<PaginationProps> = ({
  itemPerPage,
  currentPage,
  pageCount,
  totalCount,
  onPageChange,
  pageSize,
}) => {
  const { theme } = useTheme();

  const onPreviousPage = (e: any) => {
    e.preventDefault();
    onPageChange(currentPage - 1);
  };

  const onNextPage = (e: any) => {
    e.preventDefault();
    onPageChange(currentPage + 1);
  };

  const paginationRange = usePagination({
    totalCount,
    pageSize,
    currentPage,
  });
  return (
    <nav
      className={cn(
        "d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers",
        {
          [styles.dark]: theme === "dark",
          [styles.light]: theme === "light",
        }
      )}
    >
      <ul className="pagination">
        <li
          className={`page-item ${
            currentPage === 1 ? "disabled" : "active"
          } me-1`}
        >
          <button
            className={cn("page-link", styles["page-link-button"])}
            aria-label="Previous"
            onClick={onPreviousPage}
          >
            <span aria-hidden="true">«</span>
          </button>
        </li>
        {paginationRange &&
          paginationRange.map((pageNumber, key) => {
            if (pageNumber === DOTS) {
              return (
                <li className="page-item disable me-1" key={key}>
                  <span className={cn("page-link")}>&#8230;</span>
                </li>
              );
            }
            return (
              <li className={`page-item ${currentPage === pageNumber ? 'active' : ''} me-1`} key={key}>
                <button
                  className={cn("page-link", styles["page-link-button"])}
                  onClick={(e) => {
                    e.preventDefault();
                    onPageChange(Number(pageNumber))
                  }}
                >
                  {pageNumber}
                </button>
              </li>
            );
          })}
        <li className="page-item">
          <button
            className={cn(
              `page-link ${
                currentPage === pageCount || currentPage > pageCount
                  ? "disabled"
                  : "active"
              }`,
              styles["page-link-button"]
            )}
            aria-label="Next"
            onClick={onNextPage}
          >
            <span aria-hidden="true">»</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
