import { FC } from 'react';

import cn from 'classnames';

interface BookHalfFillIconProps {
  className?: string;
}

const BookHalfIcon: FC<BookHalfFillIconProps> = ({ className }) => {
  return (
    <i className={cn('fs-4 bi bi-book-half', className)} />
  );
};

export default BookHalfIcon;