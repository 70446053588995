import { FC, ReactNode } from "react";

interface ToastContainerProps {
  children: ReactNode;
}

const ToastContainer: FC<ToastContainerProps> = ({ children }) => {
  return (
    <div className="toast-container position-fixed bottom-0 end-0 p-3 m-3">
      {children}
    </div>
  );
};

export default ToastContainer;