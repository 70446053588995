import { ChangeEvent, Dispatch, FC, SetStateAction, useId } from 'react';

interface IsFullReleaseCheckboxProps {
  isFullRelease: boolean;
  setIsFullRelease: Dispatch<SetStateAction<boolean>>;
}

const IsFullReleaseCheckbox: FC<IsFullReleaseCheckboxProps> = ({ isFullRelease, setIsFullRelease }) => {
  const id = useId();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsFullRelease(e.target.checked);
  };

  return (
    <div className="col-12 d-flex">
      <label htmlFor={`is-full-release-${id}`} className="me-2">
        Полный релиз:
      </label>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id={`is-full-release-${id}`}
          onChange={handleChange}
          checked={isFullRelease}
        />
      </div>
    </div>
  );
};

export default IsFullReleaseCheckbox;