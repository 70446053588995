import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { ISoftware, softwareInitialState } from '../../types/ISoftware';
import { userService } from '../../services/userService';

interface SoftwareSelectProps {
  isLoadingMode: boolean;
  softwareID: number;
  setSoftwareID: Dispatch<SetStateAction<number>>;

}

const SoftwareSelect: FC<SoftwareSelectProps> = ({ isLoadingMode, softwareID, setSoftwareID }) => {
  const [software, setSoftware] = useState<ISoftware[]>([softwareInitialState]);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSoftwareID(Number(e.target.value));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userService.getSoftware();
        setSoftware(response);
      } catch (e) {
      }
    };

    fetchData();
  }, []);


  return (
    <div className="col-12">
      <label htmlFor="soft_id">Программное обеспечение </label>
      <div className="input-group">
        {isLoadingMode && (
          <div className="input-group-text">
            <i className="bi bi-app"></i>
          </div>
        )}
        <select
          id="soft_id"
          className="form-select"
          name="soft_id"
          onChange={handleChange}
        >
          {software.map((item: ISoftware, key: number) => (
            <option key={key} value={item.ID}>{item.name}</option>
          ))}

        </select>
      </div>
    </div>
  );
};

export default SoftwareSelect;