import { ChangeEvent, Dispatch, FC, SetStateAction, useId } from 'react';

interface IsUploadErrorCheckboxProps {
  isUploadError: boolean;
  setIsUploadError: Dispatch<SetStateAction<boolean>>;
}

const IsUploadErrorCheckbox: FC<IsUploadErrorCheckboxProps> = ({ isUploadError, setIsUploadError }) => {
  const id = useId();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsUploadError(e.target.checked);
  };

  return (
    <div className="col-12 d-flex">
      <label htmlFor={`is-upload-error-${id}`} className="me-2">
        Загрузка файла не удалась?
      </label>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id={`is-upload-error-${id}`}
          onChange={handleChange}
          checked={isUploadError}
        />
      </div>
    </div>
  );
};

export default IsUploadErrorCheckbox;