import { Dispatch, FC, useId } from "react";
import MDEditor from "@uiw/react-md-editor";
import useTheme from "../../hooks/useThemes";

interface ChangeLogInputProps {
  changeLog: string;
  setChangeLog: Dispatch<string>;
}


const ChangeLogInput: FC<ChangeLogInputProps> = ({ changeLog, setChangeLog }) => {
  const id = useId();
  const { theme } = useTheme();

  return (
    <div className="col-12">
      <label htmlFor={id}>Список изменений</label>
      <div className="form-floating">
        <MDEditor
          id={id}
          value={changeLog}
          onChange={setChangeLog}
          data-color-mode={theme === "light" ? "light" : "dark"}
          className="mt-1"
        />
      </div>
    </div>
  );
};

export default ChangeLogInput;