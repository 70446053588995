import { useEffect, useState } from "react";
import { SESSION_TYPE } from "../core/constants";


const useStorage = (key: string, initialValue: any) => {
  const [storedValue, setStoredValue] = useState(() => {
    let item: string;

    if (window.localStorage.getItem(SESSION_TYPE) === "local") {
      item = window.localStorage.getItem(key);
    } else {
      item = window.sessionStorage.getItem(key);
    }
    return item ? JSON.parse(item) : initialValue;
  });

  const setValue = (value: any) => {
    setStoredValue(value);
    setStorageItem(key, JSON.stringify(value));
  };


  useEffect(() => {
    const item = getStorageItem(key);
    if (item) {
      setStoredValue(JSON.parse(item));
    }
  }, [key]);

  return [storedValue, setValue];
};


const getStorageItem = (key: string) => {
  const sessionType = window.localStorage.getItem(SESSION_TYPE);

  if (sessionType === "local") {
    return window.localStorage.getItem(key);
  } else {
    return window.sessionStorage.getItem(key);
  }
};

const setStorageItem = (key: string, value: any) => {
  const sessionType = window.localStorage.getItem(SESSION_TYPE);

  if (sessionType === "local") {
    return window.localStorage.setItem(key, value);
  } else {
    return window.sessionStorage.setItem(key, value);
  }
};

export default useStorage;