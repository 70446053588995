import { FC, ReactNode } from 'react';
import cn from 'classnames';
import useTheme from '../../hooks/useThemes';

interface CardHeaderProps {
  children: ReactNode;
  className?: string;
}

const CardHeader: FC<CardHeaderProps> = ({ children, className }) => {
  const { theme } = useTheme();

  const cardDefaultStyle = {
    backgroundColor: theme === 'light' ? '#f8f8f8' : '#353649FF'
  };

  return (
    <div
      className={cn('card-header', className)}
      style={cardDefaultStyle}
    >
      {children}
    </div>
  );
};

export default CardHeader;
