import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import logoHigh from "../../../assets/logoHigh.png";
import Footer from "../../../components/Footer/Footer";
import { MAIN_PAGE_LINK } from "../../../core/constants";
import useTheme from "../../../hooks/useThemes";
import cn from "classnames";

const NotFoundPage: FC = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <section className={cn(
      "d-flex flex-column vh-100",
      theme === "light" ? "text-black" : "text-white")}>
      <div
        className="d-flex justify-content-center w-100"
        style={{ flex: 1 }}
      >
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img src={logoHigh} width={500} className="img-fluid" alt="logo" />
          </div>
          <div className="ms-3 col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <h1 style={{ fontSize: "60px" }}>404</h1>
            <h3>{t("page-not-found-text")}</h3>
            <p>{t("page-not-found-description-text")}</p>
            <div>
              <Link to={MAIN_PAGE_LINK} type="primary" className="btn btn-primary">
                {t("page-not-found-link")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default NotFoundPage;
