import { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import ColMd6 from "../Wrapper/Col/ColMd6";

interface TableSearchItemProps {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
}

const TableSearchItem: FC<TableSearchItemProps> = ({ searchValue, setSearchValue }) => {
  return (
    <ColMd6>
      <div className="text-md-end">
        <label className="form-label" htmlFor="search">
          <input
            id="search"
            type="search"
            className="form-control form-control-sm"
            placeholder="Поиск"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchValue(e.target.value)
            }
          />
        </label>
      </div>
    </ColMd6>
  );
};

export default TableSearchItem;