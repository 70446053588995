import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './Layout.module.scss';

import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import useTheme from '../../../hooks/useThemes';
import cn from 'classnames';

const MainLayout: FC = () => {
  const { theme } = useTheme();

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <Header />
          {/*<div className="d-flex flex-column justify-content-between">*/}
            <div
              className={cn({
                [styles.dark]: theme === 'dark',
                [styles.light]: theme === 'light'
              })}
            >
              <Outlet />
            </div>
            <Footer className="bdall" />
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
