import { ChangeEvent, Dispatch, FC, SetStateAction, useId } from "react";

interface LicenseCountComponentProps {
  licCount: number;
  setLicCount: Dispatch<SetStateAction<number>>;
}

const LicenseCountInput: FC<LicenseCountComponentProps> = ({ licCount, setLicCount }) => {
  const id = useId();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLicCount(Number(e.target.value));
  };

  return (
    <div className="col-12">
      <label htmlFor={`order_number-${id}`}> Количество лицензий</label>
      <div className="input-group">
        <div className="input-group-text">
          <i className="bi bi-lock-fill"></i>
        </div>
        <input
          type="number"
          id={`order_number-${id}`}
          className="form-control"
          placeholder="Введите кол-во лицензий"
          name="order_number"
          onChange={handleChange}
          value={licCount}
        />
      </div>
    </div>
  );
};

export default LicenseCountInput;