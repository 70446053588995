import { FC } from "react";
import { useTranslation } from "react-i18next";

import cn from "classnames";

import useTheme from "../../hooks/useThemes";
import styles from "./Footer.module.scss";

interface FooterProps {
  className?: string | undefined;
}

const Footer: FC<FooterProps> = ({ className }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <footer
      className={cn(className, styles.root, {
        [styles.dark]: theme === "dark",
        [styles.light]: theme === "light",
      })}
    >
      <div className={styles.text}>{t("footer-text")}</div>
    </footer>
  );
};

export default Footer;
