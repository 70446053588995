export interface IAdmin {
  id: number;
  email: string;
  name: string;
  surname: string;
  role: {
    id: number;
    access_level: number;
    name: string;
  };
  created_at: string;
}

export type SignInCredentials = {
  email: string;
  password: string;
}


export const adminInitialState = {
  id: 0,
  email: '',
  name: '',
  surname: '',
  role: {
    id: 0,
    access_level: 0,
    name: ''
  },
  created_at: ''
};

export interface IAdminUserData {
  ID: number;
  companyName: string;
  email: string;
  roleName: string;
  isBanned: boolean;
  lastVisitedAt: string;
  createdAt: string;
}

export interface IApiAdminUserDataResponse {
  data: IAdminUserData[];
  count: number;
  lastPage: number;
  currentPage: number;
}

export const adminUsersInitialState: IAdminUserData = {
  ID: 0,
  companyName: '',
  email: '',
  roleName: '',
  isBanned: false,
  lastVisitedAt: '',
  createdAt: ''
};

