import { FC, useEffect, useState } from "react";
import { adminService } from "../../../services/adminService";
import Container from "../../../components/Wrapper/Container";
import CardHeader from "../../../components/Card/CardHeader";
import Row from "../../../components/Wrapper/Row";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import StatWidget from "../../../components/Widgets/StatWidget";
import StatWidgetWithPercent from "../../../components/Widgets/StatWidgetWithPercent";
import useToast from "../../../hooks/useToast";
import { dashboardInitialState, IDashboard, IOsStats, osStatsInitialState } from "../../../types/IDashboard";

const AdminPanelMainPage: FC = () => {
  const { addToast } = useToast();

  const [dashboard, setDashboard] = useState<IDashboard>(dashboardInitialState);
  const [osStats, setOsStats] = useState<IOsStats>(osStatsInitialState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getDashboard();
        setDashboard(response);
      } catch (e) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    fetchData();
  }, []);

  const calcRamStat = () => {
    if (osStats.memAvailable > 1000) {
      return (osStats.memAvailable / 1024).toFixed(0) + " GB из " + (osStats.memTotal / 1024).toFixed(0) + " GB";
    } else {
      return osStats.memAvailable + " MB из " + osStats.memTotal + " MB";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getStats();
        setOsStats(response);
      } catch (e) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getStats();
        setOsStats(response);
      } catch (e) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    const intervalId = setInterval(fetchData, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Card>
      <CardHeader>
        <h6 className="mt-2">Dashboard</h6>
      </CardHeader>
      <CardBody style={{ minHeight: "calc(100vh - 102px - 73px)" }}>
        <Container>
          <Row>

            <h6>ОС</h6>
            <hr />
            <StatWidget
              title="Свободно памяти (ОЗУ)"
              titleType="text-danger"
              value={calcRamStat()}
            />

            <StatWidgetWithPercent
              title="Использовано памяти (ОЗУ)"
              titleType="text-warning"
              value={osStats.memUsed}
            />

            <StatWidget
              title="Свободно памяти (диск)"
              titleType="text-success"
              value={String(osStats.hddMemAvailable) + " " + "GB" + " из " + String(osStats.hddMemTotal) + " " + "GB"}
            />

            <StatWidgetWithPercent
              title="Использовано памяти (диск)"
              titleType="text-info"
              value={osStats.hddMemUsed}
            />

            <StatWidget
              title="Uptime"
              titleType="text-info"
              value={osStats.uptime}
            />

            <h6>AVG</h6>
            <hr />
            <StatWidget
              title="AVG1"
              titleType="text-info"
              value={osStats.avgStat.load1.toFixed(2)}
            />

            <StatWidget
              title="AVG5"
              titleType="text-info"
              value={osStats.avgStat.load5.toFixed(2)}
            />

            <StatWidget
              title="AVG15"
              titleType="text-info"
              value={osStats.avgStat.load15.toFixed(2)}
            />


            <h6>Лицензии</h6>
            <hr />
            <StatWidget
              title="Выдано лицензий (за год)"
              titleType="text-danger"
              value={dashboard.licenseStatistics.licensesIssuedPerYear}
            />
            <StatWidget
              title="Выдано лицензий (за месяц)"
              titleType="text-warning"
              value={dashboard.licenseStatistics.licensesIssuedPerMonth}
            />

            <StatWidget
              title="Выдано лицензий (за день день)"
              titleType="text-success"
              value={dashboard.licenseStatistics.licensesIssuedPerDay}
            />

            <StatWidgetWithPercent
              title="Процент выданных лицензий"
              titleType="text-info"
              value={dashboard.licenseStatistics.percentContractIssued}
            />

            <StatWidget
              title="Количество скачиваний ПО"
              titleType="text-info"
              value={dashboard.downloadSoftwareCount}
            />

            <StatWidget
              title="Количество выданных лицензий"
              titleType="text-success"
              value={dashboard.totalLicensesIssued}
            />

            <StatWidget
              title="Количество зарегистрированных пользователей"
              titleType="text-warning"
              value={dashboard.totalUsersRegistered}
            />

            <StatWidget
              title="Количество зарегистрированных компаний"
              titleType="text-danger"
              value={dashboard.totalCompanyRegistered}
            />

            <StatWidget
              title="Количество зарегистрированных контрактов"
              titleType="text-success"
              value={dashboard.totalContractsRegistered}
            />


          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};
export default AdminPanelMainPage;
