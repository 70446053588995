import { ChangeEvent, Dispatch, FC, SetStateAction, useId } from "react";

interface ContractNumberInputProps {
  contractNumber: string;
  setContractNumber: Dispatch<SetStateAction<string>>;
  isContractOrder?: boolean;
}


const ContractNumberInput: FC<ContractNumberInputProps> = ({ contractNumber, setContractNumber, isContractOrder }) => {
  const id = useId();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setContractNumber(e.target.value);
  };

  return (
    <div className="col-12">
      <label htmlFor={`contract-${id}`}>{isContractOrder ? "Номер заявки" : "Номер контракта"}</label>
      <div className="input-group">
        <div className="input-group-text">
          <i className="bi bi-lock-fill"></i>
        </div>
        <input
          type="text"
          id={`contract-${id}`}
          className="form-control"
          placeholder={isContractOrder ? "Введите номер заявки" : "Введите номер контракта"}
          name="contract_number"
          onChange={handleChange}
          value={contractNumber}
        />
      </div>
    </div>
  );
};

export default ContractNumberInput;