import { ChangeEvent, Dispatch, FC, SetStateAction, useId } from "react";

interface NeverExpireComponentsProps {
  neveExpire: boolean;
  setNeveExpire: Dispatch<SetStateAction<boolean>>;
}

const NeverExpireInput: FC<NeverExpireComponentsProps> = ({ neveExpire, setNeveExpire }) => {
  const id = useId();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNeveExpire(e.target.checked);
  };

  return (
    <div className="col-12 d-flex">
      <label htmlFor={`unlim-lic-duration-${id}`} className="me-2">
        Срок действия не ограничен:
      </label>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id={`unlim-lic-duration-${id}`}
          onChange={handleChange}
          checked={neveExpire}
        />
      </div>
    </div>
  );
};

export default NeverExpireInput;