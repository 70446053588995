import React, { ChangeEvent, Dispatch, FC } from "react";

interface OperationSystemSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  setOsID: Dispatch<number>;
}


const OperationSystemSelect: FC<OperationSystemSelectProps> = ({ setOsID, ...props }) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setOsID(Number(e.target.value));
  };

  return (
    <div className="col-12">
      <label htmlFor="op_sys_id">Операционная система</label>
      <div className="input-group">
        <div className="input-group-text">
          <i className="bi bi-x-circle-fill"></i>
        </div>
        <select
          className="form-select"
          aria-label="Default select example"
          name="op_sys_id"
          onChange={handleChange}
          defaultValue={1}
          {...props}
        >
          <option value={1}>
            Windows
          </option>
          <option value={2}>
            Linux
          </option>
          <option value={3}>
            Virtual Machine
          </option>
        </select>
      </div>
    </div>
  );
};

export default OperationSystemSelect;