import { ChangeEvent, FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { COMPANIES_CREATE_PAGE_LINK, USERS_CREATE_PAGE_LINK, USERS_PAGE_LINK } from "../../../core/constants";
import { roleService } from "../../../services/roleService";
import Container from "../../../components/Wrapper/Container";
import Row from "../../../components/Wrapper/Row";
import Col from "../../../components/Wrapper/Col/Col";
import { adminService } from "../../../services/adminService";
import { IRole, roleInitialState } from "../../../types/IRole";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import useToast from "../../../hooks/useToast";
import PageNavigate from "../../../components/Navigate/PageNavigate";
import { companiesInitialState, ICompanyResponse } from "../../../types/ICompany";


const CreateUserPage: FC = () => {
  const { addToast } = useToast();

  const [isCheckedSubmitButton, setIsCheckedSubmitButton] = useState<boolean>(true);

  const [companies, setCompanies] = useState<ICompanyResponse[]>(companiesInitialState);

  const [roles, setRoles] = useState<IRole[]>([roleInitialState]);
  const [companyId, setCompanyId] = useState(-1);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [roleID, setRoleID] = useState(0);

  const [isCompanyExist, setIsCompanyExist] = useState(false);

  const handleCompanyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCompanyId(Number(e.target.value));
  };

  const handleRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRoleID(Number(e.target.value));
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSurnameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSurname(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const data = {
      company_id: Number(companyId),
      email: email,
      name: name,
      surname: surname,
      role: roleID
    };

    try {
      const response = await adminService.createUser(data);
      if (response.data) {
        addToast(response.data.message, "success");
      }
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await adminService.getCompany();
      if (response.data !== null) {
        setCompanies(response.data);
        setIsCompanyExist(true);
      }

      const res = await roleService.getAll();
      if (res) {
        setRoles(res);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (companyId != -1 && email != "" && name != "" && surname != "") {
      setIsCheckedSubmitButton(false);
    } else {
      setIsCheckedSubmitButton(true);
    }
  }, [companyId, email, name, surname]);

  return (
    <Card>
      <CardHeader>
        <h6 className="mt-2">Создание пользователя</h6>
      </CardHeader>
      <PageNavigate
        current={USERS_CREATE_PAGE_LINK}
        to={USERS_PAGE_LINK}
        title={"Вернуться"}
      />
      <CardBody>
        <Container>
          {isCompanyExist ? (
            <Row>
              <Col>
                <div className="form-left h-100 py-5 px-5">
                  <form className="row g-4">

                    <div className="col-12">
                      <label>Компания </label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-person-fill"></i>
                        </div>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="company_id"
                          onChange={handleCompanyChange}>
                          <option selected disabled>
                            Выберите компанию
                          </option>
                          {companies.map((item, key) => (
                            <option key={key} value={item.ID}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-12">
                      <label>Роль пользователя </label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-person-fill"></i>
                        </div>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="role_id"
                          onChange={handleRoleChange}>
                          <option selected disabled>
                            Выберите роль пользователя
                          </option>
                          {roles.map((item: IRole, key: number) => (
                            <option key={key} value={item.ID}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-12">
                      <label>Email</label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-mailbox"></i>
                        </div>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Введите адрес электронной почты"
                          name="email"
                          onChange={handleEmailChange}
                          value={email}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <label>Имя</label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-mailbox"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Введите имя пользователя"
                          name="name"
                          onChange={handleNameChange}
                          value={name}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <label>Фамилия</label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-mailbox"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Введите фамилию пользователя"
                          name="surname"
                          onChange={handleSurnameChange}
                          value={surname}
                        />
                      </div>
                    </div>

                    <div className="d-flex col-12">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn btn-primary px-4 float-end mt-4"
                        disabled={isCheckedSubmitButton}
                        style={{ marginLeft: "auto" }}>
                        Создать
                      </button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          ) : (
            <div className="card-body">
              <div>Компании не зарегистрированы в системе!</div>
              <div>Создание пользователей возможно только при наличие компаний!</div>
              <Link to={COMPANIES_CREATE_PAGE_LINK}>Сначала добавьте компанию в систему!</Link>
            </div>
          )}
        </Container>
      </CardBody>
    </Card>
  );
};

export default CreateUserPage;
