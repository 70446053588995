import { FC } from 'react';

import cn from 'classnames';

interface BuildingsIconProps {
  className?: string;
}

const BuildingsIcon: FC<BuildingsIconProps> = ({ className }) => {
  return (
    <i className={cn('fs-4 bi bi-buildings', className)} />
  );
};

export default BuildingsIcon;