import { FC, ReactNode } from 'react';
import cn from 'classnames';

interface ColMd6Props {
  children: ReactNode;
  className?: string;
}

const ColMd6: FC<ColMd6Props> = ({ children, className }) => {
  return (
    <div className={cn('col-md-6', className)}>{children}</div>
  );
};

export default ColMd6;
