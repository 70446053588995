import { FC, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ACCESS_LEVEL_ADMIN,
  CONTRACT_CREATE_PAGE_LINK,
  CONTRACT_ORDER_CREATE_PAGE_LINK,
  CONTRACT_PAGE_LINK,
  getContractLicensesPageLinkByContractID,
  STATUS_CREATED
} from "../../../core/constants";
import { formatDateSimple, formatDateTime } from "../../../utils/date";
import { adminService } from "../../../services/adminService";
import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import Row from "../../../components/Wrapper/Row";
import PencilSquare from "../../../components/Icons/PencilSquare";
import { contractWithCompanyInitialState, IContractsWithCompany } from "../../../types/IContract";
import useToast from "../../../hooks/useToast";
import PaginationView from "../../../components/Pagination/PaginationView";
import TableViewItem from "../../../components/Table/TableViewItem";
import TableSearchItem from "../../../components/Table/TableSearchItem";
import AdminAccessWrapper from "../../../permission/AccessControlWrapper";

const ContractPage: FC = () => {
  const { addToast } = useToast();

  const navigate = useNavigate();
  const location = useLocation();

  const [itemViewCount, setItemViewCount] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [tableData, setTableData] =
    useState<IContractsWithCompany[]>([contractWithCompanyInitialState]);

  const [oldTableData, setOldTableData] =
    useState<IContractsWithCompany[]>([contractWithCompanyInitialState]);

  const [itemCount, setItemCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [isContractExist, setIsContractExist] = useState(false);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [rowIDToEdit, setRowIDToEdit] = useState<number | undefined>(undefined);
  const [editedRow, setEditedRow] = useState<object | undefined>();
  const [isFirstEditRowID, setIsFirstEditRowID] = useState<number>(-1);

  const getRowNumber = (index: number) => (currentPage - 1) * itemViewCount + index + 1;


  const filteredTableData = tableData.filter((val) => {
    return val.companyName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getContractWithCompanies(itemViewCount, currentPage);
        if (response !== null) {
          setTableData(response.data);
          setOldTableData(response.data);
          setItemCount(response.count);
          setLastPage(response.lastPage);
          setIsContractExist(true);
        }
      } catch (e) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };
    fetchData();
  }, [itemViewCount, currentPage]);

  const ContractTableHead: FC = () => {
    return (
      <tr>
        <th className="text-center">№</th>

        <AdminAccessWrapper>
          <th className="text-center">Действие</th>
        </AdminAccessWrapper>
        <th className="text-center">Название компании</th>
        <th className="text-center">Номер контракта</th>
        <th className="text-center">Количество лицензий</th>
        <th className="text-center">Лицензий осталось</th>
        <th className="text-center">Экспорт номеров</th>
        <th className="text-center">Дата заключения контракта</th>
        <th className="text-center">Дата создания</th>
      </tr>
    );
  };


  const handleButtonExport = async (contractID: number) => {
    try {
      const response = await adminService.exportLicensesNumberByContractID(contractID);
      const contentDisposition = response.headers["Content-Disposition"];
      let fileName = "license_numbers_contract_id_" + String(contractID);

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }
  };

  useEffect(() => {
    if (rowIDToEdit !== isFirstEditRowID) {
      setIsFirstEditRowID(rowIDToEdit);
      setTableData(oldTableData);
    }
  }, [rowIDToEdit]);

  const handleEditButtonChange = (rowID: number) => {
    setIsEditMode(true);
    setEditedRow(undefined);
    setRowIDToEdit(rowID);
  };

  const handleCloseEditButtonChange = (rowID: number) => {
    setIsEditMode(false);
    setEditedRow(undefined);
    setTableData(oldTableData);
  };


  const handleOnChangeField = (e: any, rowID: number) => {
    let value: string;

    const { name: fieldName } = e.target;

    if (fieldName === "isFullRelease" || fieldName === "isActive") {
      value = e.target.options[e.target.selectedIndex].text;
    } else {
      value = e.target.value;
    }

    setEditedRow(prevState => ({
      ...prevState,
      ID: rowID,
      [fieldName]: value
    }));

    setTableData(prevData =>
      prevData.map(row =>
        row.contractID === rowID
          ? { ...row, [fieldName]: value }
          : row
      )
    );
  };

  const compareRows = (originalRow, updatedRow) => {
    interface IChanges {
      contractNumber?: string;
    }

    const changes: IChanges = {};

    for (const key in originalRow) {
      if (originalRow[key] !== updatedRow[key]) {
        changes[key] = updatedRow[key];
      }
    }

    return changes;
  };

  const handleSaveRowChanges = async () => {
    setIsEditMode(false);
    const row = tableData.find(row => row.contractID === rowIDToEdit);
    const oldRow = oldTableData.find(row => row.contractID === rowIDToEdit);
    const changedFields = compareRows(oldRow, row);
    const formData = {};

    if (changedFields.contractNumber) {
      Object.assign(formData, { contractNumber: changedFields.contractNumber });
    }

    if (!(Object.entries(formData).length === 0)) {
      try {
        const response = await adminService.updateContractTableRow(rowIDToEdit, formData);
        if (response.status == STATUS_CREATED) {
          setOldTableData(tableData);
          addToast(response.data.message, "success");
        }
      } catch (e) {
        setTableData(oldTableData);
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    }

    setEditedRow(undefined);
  };

  const handleSwitchCreateContractPage = () => {
    if (location.pathname === CONTRACT_PAGE_LINK) {
      navigate(CONTRACT_CREATE_PAGE_LINK);
    } else {
      navigate(CONTRACT_PAGE_LINK);
    }
  };

  const handleSwitchCreateContractOrderPage = () => {
    if (location.pathname === CONTRACT_PAGE_LINK) {
      navigate(CONTRACT_ORDER_CREATE_PAGE_LINK);
    } else {
      navigate(CONTRACT_PAGE_LINK);
    }
  };

  return (
    <Card>
      <CardHeader>
        <h6 className="mt-2">Контракты</h6>
      </CardHeader>
      <Row>
        <div className="col-12">
          <button
            type="submit"
            onClick={handleSwitchCreateContractPage}
            className="btn btn-primary btn-sm px-4 mt-4 ms-4"
            style={{ marginLeft: "auto" }}
          >
            Добавить контракт
          </button>
          <button
            type="submit"
            onClick={handleSwitchCreateContractOrderPage}
            className="btn btn-primary btn-sm px-4 mt-4 ms-4"
            style={{ marginLeft: "auto" }}
          >
            Добавить заявку к контракту
          </button>
          <hr />
        </div>
      </Row>
      {isContractExist ? (
        <CardBody>
          <Row>
            <TableViewItem
              itemViewCount={itemViewCount}
              setItemViewCount={setItemViewCount}
            />
            <TableSearchItem
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </Row>
          <div
            className="table-responsive table mt-2"
            id="dataTable"
            role="grid"
            aria-describedby="dataTable_info">
            <table className="table table-striped table-bordered my-0" id="dataTable">
              <thead>
              <ContractTableHead />
              </thead>
              <tbody>
              {filteredTableData.map((row, key: number) => (
                <tr key={row.contractID}>
                  <td className="align-middle text-center">
                    {getRowNumber(key)}
                  </td>

                  <AdminAccessWrapper role={ACCESS_LEVEL_ADMIN}>
                    <td className="align-middle text-center">
                      {isEditMode && rowIDToEdit === row.contractID ? (
                        <>
                          <button style={{ all: "unset" }} onClick={handleSaveRowChanges}>
                            <i className="fs-5 bi bi-save me-2" />
                          </button>
                          <button style={{ all: "unset" }}
                                  onClick={() => handleCloseEditButtonChange(row.contractID)}>
                            <PencilSquare className="fs-5 text-danger" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button style={{ all: "unset" }} onClick={() => handleEditButtonChange(row.contractID)}>
                            <i className="fs-5 bi bi-pencil-square me-2" />
                          </button>
                        </>
                      )}
                    </td>
                  </AdminAccessWrapper>

                  <td className="align-middle text-center" style={{ width: "200px" }}>
                    {row.companyName}
                  </td>

                  {isEditMode && rowIDToEdit === row.contractID ? (
                    <td className={"align-middle"}>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="contractNumber"
                        name="contractNumber"
                        onChange={(e) => handleOnChangeField(e, row.contractID)}
                        value={row.contractNumber}
                      />
                    </td>
                  ) : (
                    <td className="align-middle text-center">
                      <Link to={getContractLicensesPageLinkByContractID(row.contractID)}>
                        {row.contractNumber}
                      </Link></td>
                  )}


                  <td className="align-middle text-center">{row.licCount}</td>
                  <td className="align-middle text-center">{row.licAvailable}</td>
                  <td className="align-middle text-center" style={{ width: "100px" }}>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleButtonExport(row.contractID)}
                    >
                      Скачать
                    </button>
                  </td>
                  <td style={{ width: "180px", verticalAlign: "middle" }} className="align-middle text-center">
                    {formatDateSimple(row.dateOfConclusion)}
                  </td>
                  <td style={{ width: "200px", verticalAlign: "middle" }} className="align-middle text-center">
                    {formatDateTime(row.createdAt)}
                  </td>
                </tr>
              ))}
              </tbody>
              <tfoot>
              <ContractTableHead />
              </tfoot>
            </table>
          </div>
          <Row>
            <PaginationView
              currentPage={currentPage}
              lastPage={lastPage}
              itemViewCount={itemViewCount}
              itemCount={itemCount}
              setCurrentPage={setCurrentPage}
            />
          </Row>
        </CardBody>
      ) : (
        <CardBody>
          <div>Контракты отсутствуют!</div>
          <Link to={CONTRACT_CREATE_PAGE_LINK}>Добавьте контракт</Link>
        </CardBody>
      )}
    </Card>
  );
};

export default ContractPage;
