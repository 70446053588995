import { FC } from 'react';

import cn from 'classnames';

interface CloudUploadFillIconProps {
  className?: string;
}

const CloudUploadFillIcon: FC<CloudUploadFillIconProps> = ({ className }) => {
  return (
    <i className={cn('fs-4 bi bi-cloud-upload-fill', className)} />
  );
};

export default CloudUploadFillIcon;