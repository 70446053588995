import { CSSProperties, FC, ReactNode } from 'react';
import cn from 'classnames';
import useTheme from '../../hooks/useThemes';

interface CardProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}


const Card: FC<CardProps> = ({ children, className, style }) => {
  const {theme} = useTheme();

  return (
    <div
      className={cn(
        'card', 'p-0',
        theme === 'light' ? 'text-black' : 'text-white',
        className
      )}
      style={style}
    >
      {children}
    </div>
  );

};

export default Card;