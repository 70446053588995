import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CcbOldFileNameRowProps {
  ccbOldFileName: string;
}

const CcbOldFileNameRow: FC<CcbOldFileNameRowProps> = ({ccbOldFileName}) => {
  const { t } = useTranslation();

  return (
    <td className={'align-middle text-center'}>{ccbOldFileName ? ccbOldFileName : t('absent-text')}</td>
  );
};

export default CcbOldFileNameRow;