import api from '../core/axios';
import { userInitialState } from '../types/IUser';

export const authService = {
  async signIn(formData: any) {
    return await api.post('/auth/sign-in', formData);
  },
  async forgotPassword(email: string) {
    return await api.post('/auth/forgot-password', { email: email });
  },

  async resetPassword(resetToken: string, password: string) {
    return await api.post('/auth/reset-password', {
      resetToken: resetToken,
      password: password
    });
  },
  logout(setIsAuth: any, setUser: any) {
    localStorage.clear();
    sessionStorage.clear();
    setIsAuth(false);
    setUser(userInitialState);
    document.cookie = 'refreshToken=;';
  }
};
