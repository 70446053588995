import { FC, useEffect, useState } from "react";

import { companyService } from "../../services/companyService";

import { companyInitialValue, ICompany } from "../../types/ICompany";
// import { contractInitialState, IContract } from "../types/IContract";
import CompanyInfo from "../../module/CompanyPage/CompanyInfo";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import useToast from "../../hooks/useToast";

const CompanyPage: FC = () => {
  const { addToast } = useToast();

  const [company, setCompany] = useState<ICompany>(companyInitialValue);
  // const [contracts, setContracts] = useState<IContract[]>([
  // contractInitialState,
  // ]);


  useEffect(() => {
    document.title = "О компании";

    const fetchData = async () => {
      try {
        const companyResponse = await companyService.getCompanyData();
        setCompany(companyResponse.data);
        // const contractResponse = await contractService.getContracts();
        // setContracts(contractResponse.data);
      } catch (e) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };
    fetchData();
  }, []);

  return (
    <Card className="mt-3" style={{ minHeight: "calc(100vh - 102px - 73px)" }}>
      <CardHeader className="py-3">
        <p className="m-0 fw-bold">О компании</p>
      </CardHeader>
      <CardBody>
        <div className="row">{/* <ProfileStatistic /> */}</div>
        <div className="row">
          <div className="col">
            <CompanyInfo company={company} />
          </div>

          <div className="col">
            {/*<ProfileStatistic />*/}
          </div>
        </div>
        <div className="row">{/* <ProfilePasswordInput /> */}</div>
        {/* </div> */}
      </CardBody>
    </Card>
    // <div className="container-fluid" style={{minHeight: "calc(100vh - 94px - 73px)"}}>
    //   <h3 className="text-dark mb-4 mt-2">Компания</h3>
    //   <div className="row">{/* <ProfileStatistic /> */}</div>
    //   <div className="row">
    //     <div className="col">
    //       <CompanyInfo company={company} />
    //     </div>
    //
    //     <div className="col">
    //       {/*<ProfileStatistic />*/}
    //     </div>
    //   </div>
    //   <div className="row">{/* <ProfilePasswordInput /> */}</div>
    //   {/* </div> */}
    // </div>
  );
};

export default CompanyPage;
