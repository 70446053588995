import { ChangeEvent, Dispatch, FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { licenseService } from "../../services/licenseService";
import { ILicense } from "../../types/ILicenses";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import useToast from "../../hooks/useToast";

interface UploadFileProps {
  contractID: number;
  data: ILicense[];
  setData: Dispatch<ILicense[]>;
}

const UploadFile: FC<UploadFileProps> = ({ contractID, data, setData }) => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<boolean>(false);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      const fileName = selectedFile.name;
      const lastDotIndex = fileName.lastIndexOf(".");
      if (lastDotIndex !== -1) {
        const fileExt = fileName
          .substring(lastDotIndex + 1)
          .toLocaleLowerCase();

        formData.append("file", selectedFile);
        formData.append("contractId", contractID.toString());

        try {
          if (fileExt === "zip") {
            const response = await licenseService.uploadZipArchive(formData);
            setError(false);
            if (response.data) {
              const updatedState = data.map((item: ILicense) => {
                const responseData = response.data.url.find(
                  (obj: any) => obj.id === item.ID
                );
                if (responseData) {
                  if (item.ID === responseData.id) {
                    return {
                      ...item,
                      ...item,
                      ccbFileName: responseData.url_ccb_file,
                      licFileName: responseData.url_lic_file
                    };
                  }
                }
                return item;
              });

              setData(updatedState);
            }
          } else if (fileExt === "ccb") {
            await licenseService.uploadSingleFile(formData);
            setError(false);
          }
        } catch (e: any) {
          if (e.response.status >= 400 && e.response.status < 500) {
            addToast(e.response.data.message, "warning");
          } else if (e.response.status >= 500) {
            addToast(e.response.data.message, "error");
          }
        }
      }
    }
  };

  return (
    <Card className="mt-2">
      <CardHeader>
        <p>{t("download-archive-text")}</p>
      </CardHeader>
      <CardBody>
        <div className="form-file" style={{ maxWidth: "800px" }}>
          <div className="input-group">
            <input
              type="file"
              className="form-control"
              accept=".zip"
              // accept=".zip", .ccb"
              onChange={handleFileChange}
            />
          </div>
          <div className="d-flex" style={{ alignItems: "center" }}>
            <button
              type="submit"
              className="btn btn-primary mt-2"
              onClick={handleUpload}
            >
              {t("submit-button-text")}
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default UploadFile;
