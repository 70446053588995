import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useTheme from '../../../../hooks/useThemes';
import cn from 'classnames';

import styles from '../Table.module.scss';

interface LicFileRowProps {
  licFileName: string;
  handleDownloadClick: (licFileName: string) => void;
}

const LicFileRow: FC<LicFileRowProps> = ({ licFileName, handleDownloadClick }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <td className={'align-middle text-center'}>
      <div>
        {licFileName ? (
          <div
            // href={item.lic_file_name}
            className={cn({
              [styles.link_button_d]: theme === 'dark',
              [styles.link_button_l]: theme === 'light'
            })}
            onClick={() => handleDownloadClick(licFileName)}
          >
            Скачать
          </div>
        ) : (
          t('absent-text')
        )}
      </div>
    </td>
  );
};

export default LicFileRow;