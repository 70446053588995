import { Dispatch, SetStateAction, createContext } from "react";

export type ThemeContextType = {
  theme: string;
  setTheme: Dispatch<SetStateAction<string>>;
};

const ThemeContext = createContext({} as ThemeContextType);

export default ThemeContext;
