import { FC } from "react";
import cn from "classnames";

type ToastType = "success" | "warning" | "error" | "info";

interface ToastProps {
  type: ToastType;
  message: string;
}

const toastIconMap = new Map<string, string>();
toastIconMap.set("success", "text-success");
toastIconMap.set("warning", "text-warning");
toastIconMap.set("error", "text-danger");
toastIconMap.set("info", "text-info");


const toastMessageMap = new Map<string, string>();
toastMessageMap.set("success", "Успешно!");
toastMessageMap.set("warning", "Предупреждение!");
toastMessageMap.set("error", "Ошибка!");
toastMessageMap.set("info", "Информация!");

const Toast: FC<ToastProps> = ({ type, message }) => {
  const header = toastMessageMap.get(type);
  const iconType = toastIconMap.get(type);

  return (
    <div
      className="toast show"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      style={{ zIndex: 100 }}
    >
      <div className="toast-header">
        <i className={cn("fs-5 bi bi-exclamation-triangle-fill me-1", iconType)} />
        <b className="me-auto">{header}</b>
        <small>сейчас</small>
        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div className="toast-body">
        {message}
      </div>
    </div>
  );
};

export default Toast;