import { ChangeEvent, useEffect, useState } from "react";
import { adminService } from "../../../services/adminService";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Row from "../../../components/Wrapper/Row";
import Col from "../../../components/Wrapper/Col/Col";
import { ILicense, licenseInitialState } from "../../../types/ILicenses";
import useToast from "../../../hooks/useToast";
import { companiesInitialState, ICompanyResponse } from "../../../types/ICompany";
import { contractNumbersByCompanyIDInitialState, IContractNumbersByCompanyID } from "../../../types/IContract";
import LicensesTable from "../../../module/MainPage/Table/LicensesTable";
import { licenseService } from "../../../services/licenseService";

const GenerateLicensePage = () => {
  const { addToast } = useToast();

  const [isContractSelect, setIsContractSelect] = useState(false);
  const [companyID, setCompanyID] = useState(-1);
  const [companies, setCompanies] = useState<ICompanyResponse[]>(companiesInitialState);
  const [contractsNumbers, setContractsNumbers]
    = useState<IContractNumbersByCompanyID[]>([contractNumbersByCompanyIDInitialState]);
  const [selectedContractID, setSelectedContractID] = useState<number>(-1);
  const [isCompanySelect, setIsCompanySelect] = useState<boolean>(false);
  const [data, setData] = useState<ILicense[]>([licenseInitialState]);
  const [itemViewCount, setItemViewCount] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [itemCount, setItemCount] = useState<number>(0);
  const [isLicExist, setIsLicExist] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companiesResponse = await adminService.getCompany();
        if (companiesResponse.data !== null) {
          setCompanies(companiesResponse.data);
        } else {
          addToast("Компании не созданы!", "success");
        }
      } catch (e) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getContractNumbersByCompanyID(companyID);
        if (response !== null) {
          setContractsNumbers(response);
        } else {
          addToast("У компании отсутствуют контракты", "warning");
        }
      } catch (e) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    if (companyID >= 0) {
      fetchData();
    }
  }, [companyID]);


  const handleCompanyIdChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCompanyID(Number(e.target.value));
    setIsCompanySelect(true);
  };

  const handleContractNumberSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedContractID(Number(e.target.value));
    setIsContractSelect(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedContractID >= 0) {
        try {
          const response = await licenseService.getLicenses(selectedContractID, currentPage, itemViewCount);
          setIsLicExist(true);

          if (response) {
            setData(response.data);
            setItemCount(response.count);
            setLastPage(response.lastPage);
            setCurrentPage(response.currentPage);
          }
        } catch (e: any) {
          if (e.response.status >= 400 && e.response.status < 500) {
            addToast(e.response.data.message, "warning");
          } else if (e.response.status >= 500) {
            addToast(e.response.data.message, "error");
          }
        }
      }
    };

    fetchData();
  }, [selectedContractID, currentPage, itemViewCount, isLicExist]);


  return (
    <Card>
      <CardHeader>
        <h6 className="mt-2">Сгенерировать лицензию</h6>
      </CardHeader>
      <CardBody>
        <>
          <Row>
            <Col>
              <div className="form-left h-100 py-5 px-5">
                <form className="row g-4">
                  <div className="col-12">
                    <label>Компания </label>
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="bi bi-person-fill"></i>
                      </div>
                      <select
                        className="form-select"
                        name="company_id"
                        onChange={handleCompanyIdChange}>
                        <option selected disabled>
                          Выберите компанию
                        </option>
                        {companies.map((item, key) => (
                          <option key={key} value={item.ID}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {isCompanySelect && (
                    <div className="col-12">
                      <label>Контракты </label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-person-fill"></i>
                        </div>
                        <select
                          className="form-select"
                          name="company_id"
                          onChange={handleContractNumberSelectChange}>
                          defaultValue={-5}
                          <option value={-5}>
                            Выберите контракт
                          </option>
                          {contractsNumbers.map((item) => (
                            <option key={item.ID} value={item.ID}>
                              {item.ContractNumber}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}

                  {isContractSelect && selectedContractID >= 0 && (
                    <>
                      <hr />
                      <LicensesTable
                        contractID={selectedContractID}
                        data={data}
                        setData={setData}
                        itemViewCount={itemViewCount}
                        setItemViewCount={setItemViewCount}
                        isLicExist={isLicExist}
                        currentPage={currentPage}
                        itemCount={itemCount}
                        lastPage={lastPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </>
                  )}
                </form>
              </div>
            </Col>
          </Row>
        </>
      </CardBody>
    </Card>

  );
};
export default GenerateLicensePage;
