import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CompanySelect from "../../../components/Contract/CompanySelect";
import ContractNumberInput from "../../../components/Contract/ContractNumberInput";
import DateOfConclusionContractInput from "../../../components/Contract/DateOfConclusionContractInput";
import ExpireDateInput from "../../../components/Contract/ExpireDateInput";
import LicenseCountInput from "../../../components/Contract/LicenseCountInput";
import LicVersionInput from "../../../components/Contract/LicVersionInput";
import HwFixComponents from "../../../components/Contract/HwFixComponents";
import NeverExpireInput from "../../../components/Contract/NeverExpireInput";
import PluginsInput from "../../../components/Contract/PluginsInput";
import DescriptionTextInput from "../../../components/Contract/DescriptionTextInput";
import { COMPANIES_CREATE_PAGE_LINK, CONTRACT_CREATE_PAGE_LINK, CONTRACT_PAGE_LINK } from "../../../core/constants";
import { adminService } from "../../../services/adminService";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Col from "../../../components/Wrapper/Col/Col";
import Row from "../../../components/Wrapper/Row";
import { pluginInitialStateInput } from "../../../types/ILicenses";
import useToast from "../../../hooks/useToast";
import PageNavigate from "../../../components/Navigate/PageNavigate";
import { IContractInput } from "../../../types/IContract";
import { companiesInitialState, ICompanyResponse } from "../../../types/ICompany";
import GuaranteeLetterInput from "../../../components/Contract/GuaranteeLetterInput";
import UploadProgramInput from "../../../module/UploadPage/UploadProgramInput";


const CreateContractPage: FC = () => {
  const { addToast } = useToast();

  const [isCheckedSubmitButton, setIsCheckedSubmitButton] = useState<boolean>(true);
  const [companies, setCompanies]
    = useState<ICompanyResponse[]>(companiesInitialState);

  const [companyID, setCompanyID] = useState(-1);
  const [contractNumber, setContractNumber] = useState("");
  const [licCount, setLicCount] = useState(0);
  const [licVersion, setLicVersion] = useState(2);
  const [dateOfConclusion, setDateOfConclusion] = useState(String(new Date()));
  const [expirationDate, setExpirationDate] = useState(String(new Date()));
  const [hwFix, setHwFix] = useState(false);
  const [neverExpire, setNeverExpire] = useState(false);
  const [isCompanyExist, setIsCompanyExist] = useState(false);
  const [isOrderNumberExist, setIsOrderNumberExist] = useState(false);
  const [plugins, setPlugins] = useState(pluginInitialStateInput);
  const [description, setDescription] = useState<string>("");
  const [docID, setDocID] = useState(0);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [guaranteeLetterEnabled, setGuaranteeLetterEnabled] = useState<boolean>(false);
  const [contractWarrantyDetails, setContractWarrantyDetails] = useState<string>("");
  const [isDebtPaid, setIsDebtPaid] = useState<boolean>(false);
  const [debtPaidDate, setDebtPaidDate] = useState<string>(String(new Date()));


  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const formData: IContractInput = {
      company_id: companyID,
      contract_number: contractNumber,
      is_order_number_exist: isOrderNumberExist,
      date_of_conclusion: new Date(dateOfConclusion).toISOString(),
      lic_count: licCount,
      lic_version: licVersion,
      hw_fix: hwFix,
      never_expire: neverExpire,
      description: description,
      plugins: {
        smtcp: plugins.smtcp,
        opc_ua: plugins.opcUa,
        modbus: plugins.modbus,
        redundancy: plugins.redundancy,
        elna: plugins.elna,
        online_transfer: plugins.onlineTransfer,
        archiver: plugins.archiver
      }
    };

    if (!neverExpire) {
      Object.assign(formData, { expiration_date: new Date(expirationDate).toISOString() });
    }

    if (guaranteeLetterEnabled) {
      Object.assign(formData, { warranty_obligations: {} });
      Object.assign(formData.warranty_obligations, { warranty_exist: guaranteeLetterEnabled });
      Object.assign(formData.warranty_obligations, { details: contractWarrantyDetails });

      if (isDebtPaid) {
        Object.assign(formData.warranty_obligations, { is_debt_paid: isDebtPaid });
        Object.assign(formData.warranty_obligations, { debt_paid_date: debtPaidDate });
      }

      if (isFileUploaded) {
        Object.assign(formData.warranty_obligations, { doc_id: docID });
      }

    }


    try {

      const response: any = await adminService.createContract(formData);
      if (response) {
        addToast(response.data.message, "success");
      }
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getCompany();
        if (response.data !== null) {
          setCompanies(response.data);
          setIsCompanyExist(true);
        }
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (
      companyID !== -1 &&
      contractNumber !== "" &&
      licCount !== 0 &&
      licVersion !== 0 &&
      dateOfConclusion !== "" &&
      (expirationDate !== "" || neverExpire)
    ) {
      setIsCheckedSubmitButton(false);
    } else {
      setIsCheckedSubmitButton(true);
    }
  }, [
    companyID,
    contractNumber,
    licCount,
    licVersion,
    dateOfConclusion,
    expirationDate,
    neverExpire
  ]);

  useEffect(() => {
    if (!guaranteeLetterEnabled) {
      setIsCheckedSubmitButton(false);
    } else {
      if (contractWarrantyDetails !== "") {
        setIsCheckedSubmitButton(false);
      } else {
        setIsCheckedSubmitButton(true);
      }
    }
  }, [guaranteeLetterEnabled, contractWarrantyDetails]);

  return (
    <Card>
      <CardHeader>
        <h6 className="mt-2">Добавить контракт</h6>
      </CardHeader>
      <PageNavigate
        current={CONTRACT_CREATE_PAGE_LINK}
        to={CONTRACT_PAGE_LINK}
        title={"Вернуться"}
      />
      <CardBody>
        {isCompanyExist ? (
          <Row>
            <Col>
              <div className="form-left h-100 py-5 px-5">
                <form className="row g-4">
                  <CompanySelect
                    companies={companies}
                    setCompanyID={setCompanyID}
                  />

                  {companyID !== -1 && (
                    <>
                      <ContractNumberInput
                        contractNumber={contractNumber}
                        setContractNumber={setContractNumber}
                      />

                      {guaranteeLetterEnabled && (
                        <hr />
                      )}

                      <GuaranteeLetterInput
                        guaranteeLetterEnabled={guaranteeLetterEnabled}
                        setGuaranteeLetterEnabled={setGuaranteeLetterEnabled}
                      />


                      {guaranteeLetterEnabled && (
                        <>
                          <div className="col-12 d-flex">
                            <label htmlFor={`isDebtPaid`} className="me-2">
                              Обязательства выполнены ?
                            </label>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={`isDebtPaid`}
                                name="isDebtPaid"
                                checked={isDebtPaid}
                                onChange={(e) => {
                                  setIsDebtPaid(e.target.checked);
                                }}
                              />
                            </div>
                          </div>

                          {isDebtPaid && (
                            <div className="col-12">
                              <label htmlFor={`dateOfConclusion-${1}`}>Дата погашения задолжности</label>
                              <div className="input-group">
                                <div className="input-group-text">
                                  <i className="bi bi-calendar-date"></i>
                                </div>
                                <input
                                  type="date"
                                  id={`dateOfIssue-${1}`}
                                  className="form-control"
                                  name="date_of_issue"
                                  value={debtPaidDate}
                                  onChange={(e) => {
                                    setDebtPaidDate(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          )}


                          <UploadProgramInput
                            isFileUploaded={isFileUploaded}
                            setIsFileUploaded={setIsFileUploaded}
                            setUploadedFileID={setDocID}
                          />

                          <div className="col-12">
                            <div className="form-floating">
                              <textarea
                                className="form-control"
                                placeholder="Leave a comment here"
                                id={`description-${1}`}
                                value={contractWarrantyDetails}
                                onChange={(e) => {
                                  setContractWarrantyDetails(e.target.value);
                                }}
                              />
                              <label className="ms-2" htmlFor={`description-${1}`}>
                                Описание
                              </label>
                            </div>
                          </div>
                          <hr />
                        </>

                      )}


                      <DateOfConclusionContractInput
                        dateOfConclusion={dateOfConclusion}
                        setDateOfConclusion={setDateOfConclusion}
                      />

                      {!isOrderNumberExist && (
                        <>
                          <ExpireDateInput
                            expirationDate={expirationDate}
                            setExpirationDate={setExpirationDate}
                            neverExpire={neverExpire}
                          />

                          <LicenseCountInput
                            licCount={licCount}
                            setLicCount={setLicCount}
                          />

                          <LicVersionInput
                            licVersion={licVersion}
                            setLicVersion={setLicVersion}
                          />

                          <HwFixComponents
                            hwFix={hwFix}
                            setHwFix={setHwFix}
                          />

                          <NeverExpireInput
                            neveExpire={neverExpire}
                            setNeveExpire={setNeverExpire}
                          />

                          <PluginsInput
                            plugins={plugins}
                            setPlugins={setPlugins}
                          />

                          <DescriptionTextInput
                            description={description}
                            setDescription={setDescription}
                          />
                        </>
                      )}

                      <div className="d-flex col-12">
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="btn btn-primary px-4 float-end mt-4"
                          disabled={isCheckedSubmitButton}
                          style={{ marginLeft: "auto" }}>
                          Создать
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </Col>
          </Row>
        ) : (
          <div className="card-body">
            <div>Компании не зарегистрированы в системе!</div>
            <div>Создание контрактов возможно только при наличие компаний!</div>
            <Link to={COMPANIES_CREATE_PAGE_LINK}>Сначала добавьте компанию в систему!</Link>
          </div>
        )}
      </CardBody>
    </Card>

  );
};

export default CreateContractPage;
