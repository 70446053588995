import { FC } from 'react';
import { getDateWithTime } from '../../../../utils/date';
import { useTranslation } from 'react-i18next';

interface IssueDateProps {
  issueDate: string;
}

const IssueDate: FC<IssueDateProps> = ({ issueDate }) => {
  const { t } = useTranslation();

  return (
    <td className={'align-middle text-center'}>
      {issueDate ? getDateWithTime(issueDate) : t('absent-text')}
    </td>
  );
};

export default IssueDate;