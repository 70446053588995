import { FC, useEffect, useState } from "react";
import SoftwareSelect from "../../../module/UploadPage/SoftwareSelect";
import ProgramSelect from "../../../module/UploadPage/ProgramSelect";
import OperationSystemSelect from "../../../module/UploadPage/OpetationSystemSelect";
import VersionProgramInput from "../../../module/UploadPage/VersionProgramInput";
import DescriptionInput from "../../../module/UploadPage/DescriptionInput";
import ChangeLogInput from "../../../module/UploadPage/ChangeLogInput";
import UploadProgramInput from "../../../module/UploadPage/UploadProgramInput";
import { adminService } from "../../../services/adminService";
import Container from "../../../components/Wrapper/Container";
import Row from "../../../components/Wrapper/Row";
import Col from "../../../components/Wrapper/Col/Col";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import ReleaseDateInput from "../../../module/UploadPage/ReleaseDateInput";
import IsFullReleaseCheckbox from "../../../module/UploadPage/IsFullReleaseCheckbox";
import IsUploadErrorCheckbox from "../../../module/UploadPage/IsUploadErrorCheckbox";
import useToast from "../../../hooks/useToast";
import { SOFTWARE_CREATE_PAGE_LINK, SOFTWARE_PAGE_LINK } from "../../../core/constants";
import PageNavigate from "../../../components/Navigate/PageNavigate";

const SoftwareUploadPage: FC = () => {
  const { addToast } = useToast();

  const [softwareID, setSoftwareID] = useState<number>(1);
  const [softAppID, setSoftAppID] = useState<number>(1);
  const [osID, setOsID] = useState<number>(1);
  const [versionProgram, setVersionProgram] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [changeLog, setChangeLog] = useState<string>("");
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [isCheckedSubmitButton, setIsCheckedSubmitButton] = useState<boolean>(false);
  const [uploadedFileID, setUploadedFileID] = useState<number>(0);
  const [releaseDate, setReleaseDate] = useState<string>("");
  const [isFullRelease, setIsFullRelease] = useState<boolean>(true);
  const [branch, setBranch] = useState<string>("");

  const [isUploadError, setIsUploadError] = useState<boolean>(false);
  const [numUploadID, setNumUploadID] = useState<number>(0);
  const [isFileResetForm, setIsFileResetForm] = useState<boolean>(false);

  useEffect(() => {
    if ((isFileUploaded === true || isUploadError)
      && description !== ""
      && changeLog !== ""
      && releaseDate !== ""
      && branch !== "") {
      setIsCheckedSubmitButton(false);
    } else {
      setIsCheckedSubmitButton(true);
    }
  }, [isFileUploaded, isUploadError, description, changeLog, releaseDate, branch]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let fileID = 0;
    if (isUploadError)
      fileID = numUploadID;
    else
      fileID = uploadedFileID;

    const formData = {
      software_id: softwareID,
      os_id: osID,
      soft_app_id: softAppID,
      version: versionProgram,
      release_date: releaseDate,
      description: description,
      change_log: changeLog,
      file_id: fileID,
      is_active: true,
      is_full_release: isFullRelease,
      branch: branch
    };

    try {
      const response: any = await adminService.createSoftwareUpdate(formData);
      if (response) {
        addToast(response.data.message, "success");
      }
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    } finally {
      setIsFileResetForm(true);
      setIsFileUploaded(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <h6 className="mt-2">Загрузка обновления ПО</h6>
      </CardHeader>
      <PageNavigate
        current={SOFTWARE_CREATE_PAGE_LINK}
        to={SOFTWARE_PAGE_LINK}
        title={"Вернуться"}
      />
      <CardBody>
        <Container>
          <Row>
            <Col>
              <div className="form-left h-100 py-5 px-5">
                <form className="row g-3">

                  <SoftwareSelect
                    isLoadingMode={true}
                    softwareID={softwareID}
                    setSoftwareID={setSoftwareID}
                  />
                  <ProgramSelect
                    isLoadingMode={true}
                    softAppID={softAppID}
                    setSoftAppID={setSoftAppID}
                  />
                  <OperationSystemSelect setOsID={setOsID} />
                  <VersionProgramInput
                    versionProgram={versionProgram}
                    setVersionProgram={setVersionProgram}
                  />

                  <div className="col-12">
                    <label>Название ветки</label>
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="bi bi-mailbox"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Введите название ветки"
                        name="branch"
                        onChange={(e) => {
                          setBranch(e.target.value);
                        }}
                        value={branch}
                      />
                    </div>
                  </div>

                  <IsFullReleaseCheckbox
                    isFullRelease={isFullRelease}
                    setIsFullRelease={setIsFullRelease}
                  />

                  <DescriptionInput
                    description={description}
                    setDescription={setDescription}
                  />
                  <ChangeLogInput
                    changeLog={changeLog}
                    setChangeLog={setChangeLog}
                  />

                  <ReleaseDateInput
                    releaseDate={releaseDate}
                    setReleaseDate={setReleaseDate}
                  />

                  {osID === 3 && (
                    <IsUploadErrorCheckbox
                      isUploadError={isUploadError}
                      setIsUploadError={setIsUploadError}
                    />
                  )}

                  {isUploadError && (
                    <div className="col-12">
                      <label>ID загруженного файла</label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-mailbox"></i>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Введите версию ПО"
                          name="name"
                          onChange={e => setNumUploadID((Number(e.target.value)))}
                          value={numUploadID}
                        />
                      </div>
                    </div>
                  )}

                  {!isUploadError && (
                    <UploadProgramInput
                      isFileUploaded={isFileUploaded}
                      setIsFileUploaded={setIsFileUploaded}
                      setUploadedFileID={setUploadedFileID}
                      isFileResetForm={isFileResetForm}
                      setIsFileResetForm={setIsFileResetForm}
                    />
                  )}

                  <div className="d-flex col-12">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-primary px-4 float-end mt-4"
                      disabled={isCheckedSubmitButton}
                      style={{ marginLeft: "auto" }}
                    >
                      Создать
                    </button>
                  </div>

                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};

export default SoftwareUploadPage;
