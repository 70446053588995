import { ChangeEvent, Dispatch, FC, SetStateAction, useId } from "react";

interface DescriptionTextInputProps {
  description: string;
  setDescription: Dispatch<SetStateAction<string>>;
  isContractOrder?: boolean;
}

const DescriptionTextInput: FC<DescriptionTextInputProps> = ({ description, setDescription, isContractOrder }) => {
  const id = useId();

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  return (
    <div className="col-12">
      <div className="form-floating">
        <textarea
          className="form-control"
          placeholder="Leave a comment here"
          id={`description-${id}`}
          value={description}
          onChange={handleChange}
        />
        <label className="ms-2" htmlFor={`description-${id}`}>
          {isContractOrder ? "Описание заявки к контракту" : "Описание контракта"}
        </label>
      </div>
    </div>
  );
};

export default DescriptionTextInput;
