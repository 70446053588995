import { FC } from 'react';
import useTheme from '../../hooks/useThemes';

import logo from '../../assets/logo.png';
import yellowLogo from '../../assets/yellowLogo.png';

const LogoIcon: FC = () => {
  const { theme } = useTheme();

  return (
    <img
      src={theme === 'dark' ? yellowLogo : logo}
      alt="logo"
      width={60}
    />
  );
};

export default LogoIcon;