import { ChangeEvent, Dispatch, FC, SetStateAction, useId } from "react";

interface ExpireDateInputProps {
  expirationDate: string;
  neverExpire: boolean;
  setExpirationDate: Dispatch<SetStateAction<string>>;
}

const ExpireDateInput: FC<ExpireDateInputProps> = ({ expirationDate, neverExpire, setExpirationDate }) => {
  const id = useId();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setExpirationDate(e.target.value);
  };

  return (
    <div className="col-12">
      <label htmlFor={`dateOfConclusion-${id}`}>Дата окончания лицензий</label>
      <div className="input-group">
        <div className="input-group-text">
          <i className="bi bi-calendar-date"></i>
        </div>
        <input
          type="date"
          id={`dateOfIssue-${id}`}
          className="form-control"
          name="date_of_issue"
          value={expirationDate}
          disabled={neverExpire}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default ExpireDateInput;