import { FC } from "react";

import styles from "./Table.module.scss";

const TableHead: FC = () => {
  return (
    <thead className={styles.table_head}>
    <tr>
      <th style={{ width: "20px", textAlign: "center" }}>№</th>
      <th style={{ width: "230px", textAlign: "center" }}>Номер лицензии</th>
      <th style={{ width: "80px", textAlign: "center" }}>Срок лицензии</th>
      <th style={{ width: "100px", textAlign: "center" }}>Название файла конфигурации</th>
      <th style={{ width: "180px", textAlign: "center" }}>Файл лицензии</th>
      <th style={{ width: "180px", textAlign: "center" }}>Файл оборудования</th>
      <th style={{ width: "330px", textAlign: "center" }}>Описание</th>
      <th style={{ width: "175px", textAlign: "center" }}>Дата выдачи</th>
    </tr>
    </thead>
  );
};

export default TableHead;
