import { FC, useEffect, useId, useState } from "react";
import {
  FILE_SERVER_URL,
  SOFTWARE_CREATE_PAGE_LINK,
  SOFTWARE_PAGE_LINK,
  STATUS_CREATED,
  USERS_CREATE_PAGE_LINK
} from "../../../core/constants";
import { formatDateSimple, formatDateTime } from "../../../utils/date";
import { adminService } from "../../../services/adminService";
import PencilSquare from "../../../components/Icons/PencilSquare";
import TrashIcon from "../../../components/Icons/TrashIcon";
import { ISoftwareVersion, softwareVersionInitialState } from "../../../types/ISoftware";
import useToast from "../../../hooks/useToast";
import { bytesToMB } from "../../../utils/utils";
import { userService } from "../../../services/userService";
import PaginationView from "../../../components/Pagination/PaginationView";
import Row from "../../../components/Wrapper/Row";
import CardBody from "../../../components/Card/CardBody";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import TableViewItem from "../../../components/Table/TableViewItem";
import TableSearchItem from "../../../components/Table/TableSearchItem";
import AdminAccessWrapper from "../../../permission/AccessControlWrapper";

const SoftwarePage: FC = () => {
    const id = useId();
    const { addToast } = useToast();
    const navigate = useNavigate();
    const location = useLocation();

    const [itemViewCount, setItemViewCount] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const [tableData, setTableData] =
      useState<ISoftwareVersion[] | null>([softwareVersionInitialState]);

    const [oldTableData, setOldTableData] =
      useState([softwareVersionInitialState]);

    const [itemCount, setItemCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [isUserExist, setIsUserExist] = useState(false);


    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [rowIDToEdit, setRowIDToEdit] = useState<number | undefined>(undefined);
    const [isFirstEditRowID, setIsFirstEditRowID] = useState<number>(-1);

    const getRowNumber = (index: number) => (currentPage - 1) * itemViewCount + index + 1;

    const filteredTableData = tableData.filter((val: ISoftwareVersion) => {
      return (
        val.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        val.version.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        val.releaseDate.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
      );
    });

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await adminService.getSoftwareVersions(itemViewCount, currentPage, 1, 0);
          if (response !== null) {
            setTableData(response.data);
            setItemCount(response.count);
            setLastPage(response.lastPage);
            setOldTableData(response.data);
            setIsUserExist(true);

            console.log('response', response);
          }
        } catch (e) {
          if (e.response.status >= 400 && e.response.status < 500) {
            addToast(e.response.data.message, "warning");
          } else if (e.response.status >= 500) {
            addToast(e.response.data.message, "error");
          }
        }
      };
      fetchData();
    }, [itemViewCount, currentPage]);

    const SoftwareTableHead: FC = () => {
      return (
        <tr>
          <th className="text-center">№</th>
          <AdminAccessWrapper>
            <th className="text-center">Действие</th>
          </AdminAccessWrapper>
          <th className="text-center">Название</th>
          <th className="text-center">Версия</th>
          <th className="text-center">Дата релиза</th>
          <th className="text-center">Полный релиз</th>
          <th className="text-center">Активная версия</th>
          <th className="text-center">Ветка</th>
          <th className="text-center">ОС</th>
          <th className="text-center">Имя файла</th>
          <th className="text-center">Размер файла</th>
          <th className="text-center">Скачать</th>
          <th className="text-center">CRC32</th>
          <th className="text-center">Загрузки</th>
          <th className="text-center">Дата создания</th>
        </tr>
      );
    };

    useEffect(() => {
      if (rowIDToEdit !== isFirstEditRowID) {
        setIsFirstEditRowID(rowIDToEdit);
        setTableData(oldTableData);
      }
    }, [rowIDToEdit]);

    const handleEditButtonChange = (rowID: number) => {
      setIsEditMode(true);
      setRowIDToEdit(rowID);
    };

    const handleCloseEditButtonChange = (rowID: number) => {
      setIsEditMode(false);
      setTableData(oldTableData);
    };

    const handleRemoveRowChange = async (rowID: number) => {
      const confirm = window.confirm(`Вы уверены, что хотите ПО с id = ${rowID}`);
      if (confirm) {
        const confirm2 = window.confirm(`Вы точно уверены, что хотите ПО с id = ${rowID}`);
        if (confirm2) {
          try {
            const response = await adminService.deleteSoftwareVersionByID(rowID);
            if (response.status == STATUS_CREATED) {
              setTableData(prevState => prevState.filter(row => row.ID !== rowID));
              setOldTableData(prevState => prevState.filter(row => row.ID !== rowID));
              addToast(response.data.message, "success");
            }
          } catch (e) {
            setTableData(oldTableData);
            if (e.response.status >= 400 && e.response.status < 500) {
              addToast(e.response.data.message, "warning");
            } else if (e.response.status >= 500) {
              addToast(e.response.data.message, "error");
            }
          }
        }
      }
    };

    const handleOnChangeField = (e: any, rowID: number) => {
      let value: string;

      const { name: fieldName } = e.target;

      if (fieldName === "isFullRelease" || fieldName === "isActive") {
        // value = e.target.options[e.target.selectedIndex].text;
        value = e.target.checked;
      } else {
        value = e.target.value;
      }

      setTableData(prevData =>
        prevData.map(row =>
          row.ID === rowID
            ? { ...row, [fieldName]: value }
            : row
        )
      );
    };

    const compareRows = (originalRow, updatedRow) => {
      interface IChanges {
        version?: string;
        isFullRelease?: string;
        isActive?: string;
        fileName?: string;
        branch?: string;
      }

      const changes: IChanges = {};

      for (const key in originalRow) {
        if (originalRow[key] !== updatedRow[key]) {
          changes[key] = updatedRow[key];
        }
      }

      return changes;
    };

    const handleSaveRowChanges = async () => {
      setIsEditMode(false);

      const row = tableData.find(row => row.ID === rowIDToEdit);
      const oldRow = oldTableData.find(row => row.ID === rowIDToEdit);
      const changedFields = compareRows(oldRow, row);
      const formData = {};

      if (changedFields.version) {
        Object.assign(formData, { version: changedFields.version });
      }

      if (changedFields.hasOwnProperty("isFullRelease")) {
        Object.assign(formData, { isFullRelease: changedFields.isFullRelease });
      }

      if (changedFields.hasOwnProperty("isActive")) {
        Object.assign(formData, { isActive: changedFields.isActive });
      }

      if (changedFields.fileName) {
        Object.assign(formData, { fileName: changedFields.fileName });
      }

      if (changedFields.branch) {
        Object.assign(formData, { branch: changedFields.branch });
      }

      if (!(Object.entries(formData).length === 0)) {
        try {
          const response = await adminService.updateSoftwareVersion(rowIDToEdit, formData);
          if (response.status == STATUS_CREATED) {
            setOldTableData(tableData);
            addToast(response.data.message, "success");
          }
        } catch (e) {
          setTableData(oldTableData);
          if (e.response.status >= 400 && e.response.status < 500) {
            addToast(e.response.data.message, "warning");
          } else if (e.response.status >= 500) {
            addToast(e.response.data.message, "error");
          }
        }
      }
    };

    const handleDownloadClick = async (fileName: string) => {
      try {
        const response = await userService.generateShortLink(fileName);
        if (response) {
          const link = document.createElement("a");
          link.href = FILE_SERVER_URL + response.data.downloadUrl;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      } catch (e) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    const handleSwitchPage = () => {
      if (location.pathname === SOFTWARE_PAGE_LINK) {
        navigate(SOFTWARE_CREATE_PAGE_LINK);
      } else {
        navigate(SOFTWARE_PAGE_LINK);
      }
    };

    const handleSendEmailNotification = async (e: any) => {
      e.preventDefault();
      try {
        const response = await adminService.sendSoftwareNotification();
        if (response.status == STATUS_CREATED) {
          addToast(response.data.message, "success");
        }
      } catch (e) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }

    };


    return (
      <Card>
        <CardHeader>
          <h6 className="mt-2">Загруженное ПО</h6>
        </CardHeader>
        <AdminAccessWrapper>
          <Row>
            <div className="col-12">
              <button
                type="submit"
                onClick={handleSwitchPage}
                className="btn btn-primary btn-sm px-4 mt-4 ms-4"
                style={{ marginLeft: "auto" }}
              >
                Добавить
              </button>
              <button
                type="submit"
                onClick={handleSendEmailNotification}
                className="btn btn-primary btn-sm px-4 mt-4 ms-4"
                style={{ marginLeft: "auto" }}
              >
                Отправить уведомления
              </button>
              <hr />
            </div>
          </Row>
        </AdminAccessWrapper>
        {isUserExist ? (
          <CardBody className="">
            <Row>
              <TableViewItem
                itemViewCount={itemViewCount}
                setItemViewCount={setItemViewCount}
              />
              <TableSearchItem
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </Row>
            <Row>
              <div className="table-responsive">
                <table className="table table-striped table-bordered my-0">
                  <thead>
                  <SoftwareTableHead />
                  </thead>
                  <tbody>
                  {filteredTableData.map((row: ISoftwareVersion, key: number) => (
                    <tr key={row.ID}>
                      <td className={"align-middle text-center"}>{getRowNumber(key)}</td>

                      <AdminAccessWrapper>
                        <td className={"align-middle text-center"}>
                          {isEditMode && rowIDToEdit === row.ID ? (
                            <>
                              <button style={{ all: "unset" }} onClick={handleSaveRowChanges}>
                                <i className="fs-5 bi bi-save me-2" />
                              </button>
                              <button style={{ all: "unset" }} onClick={() => handleCloseEditButtonChange(row.ID)}>
                                <PencilSquare className="fs-5 text-danger" />
                              </button>
                            </>
                          ) : (
                            <>
                              <button style={{ all: "unset" }} onClick={() => handleEditButtonChange(row.ID)}>
                                <i className="fs-5 bi bi-pencil-square me-2" />
                              </button>
                              <button style={{ all: "unset" }} onClick={() => handleRemoveRowChange(row.ID)}>
                                <TrashIcon className="fs-5 me-2" />
                              </button>
                            </>
                          )}
                        </td>
                      </AdminAccessWrapper>

                      <td className={"align-middle"} style={{ width: "110px" }}>{row.name}</td>

                      {isEditMode && rowIDToEdit === row.ID ? (
                        <td className={"align-middle"}>
                          <input
                            type="text"
                            id={`version-${id}`}
                            className="form-control form-control-sm"
                            placeholder="version"
                            name="version"
                            onChange={(e) => handleOnChangeField(e, row.ID)}
                            value={row.version}
                          />
                        </td>
                      ) : (
                        <td className="align-middle text-center">
                          <Link to={SOFTWARE_PAGE_LINK + "/" + row.ID}>
                            {row.version}
                          </Link>
                        </td>
                      )}

                      <td className={"align-middle"} style={{ width: "95px" }}>{formatDateSimple(row.releaseDate)}</td>

                      {isEditMode && rowIDToEdit === row.ID ? (
                        <td className={"align-middle"}>
                          <div className="col-12 d-flex">
                            <label htmlFor={`is-isFullRelease-${id}`} className="me-2">
                              Полный
                            </label>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name="isFullRelease"
                                id={`is-isFullRelease-${id}`}
                                onChange={(e) => handleOnChangeField(e, row.ID)}
                                checked={row.isFullRelease}
                              />
                            </div>
                          </div>
                        </td>
                      ) : (
                        <td className={"align-middle text-center"}
                            style={{ width: "95px" }}>{row.isFullRelease ? "Да" : "Нет"}</td>
                      )}

                      {isEditMode && rowIDToEdit === row.ID ? (
                        <td className={"align-middle"}>
                          <div className="col-12 d-flex">
                            <label htmlFor={`is-active-${id}`} className="me-2">
                              Активная
                            </label>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name="isActive"
                                id={`is-active-${id}`}
                                onChange={(e) => handleOnChangeField(e, row.ID)}
                                checked={row.isActive}
                              />
                            </div>
                          </div>
                        </td>
                      ) : (
                        <td className={"align-middle text-center"}
                            style={{ width: "95px" }}>{row.isActive ? "Да" : "Нет"}</td>
                      )}

                      {isEditMode && rowIDToEdit === row.ID ? (
                        <td className={"align-middle"}>
                          <input
                            type="text"
                            id={`branch-${id}`}
                            className="form-control form-control-sm"
                            placeholder="branch"
                            name="branch"
                            onChange={(e) => handleOnChangeField(e, row.ID)}
                            value={row.branch}
                          />
                        </td>
                      ) : (
                        <td className={"align-middle text-center"}>{row.branch}</td>
                      )}


                      <td className={"align-middle text-center"}>{row.operationSystemName}</td>


                      {isEditMode && rowIDToEdit === row.ID ? (
                        <td className={"align-middle"}>
                          <input
                            type="text"
                            id={`fileName-${id}`}
                            className="form-control form-control-sm"
                            placeholder="fileName"
                            name="fileName"
                            onChange={(e) => handleOnChangeField(e, row.ID)}
                            value={row.fileName}
                          />
                        </td>
                      ) : (
                        <td className="align-middle text-center">{row.fileName}</td>
                      )}


                      <td className="align-middle text-center" style={{ width: "95px" }}>{bytesToMB(row.fileSize)} Мб</td>
                      <td className="align-middle text-center">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleDownloadClick(row.fileName)}
                        >
                          Скачать
                        </button>
                      </td>
                      <td className="align-middle text-center">{"0x" + row.fileCrc32.toString(16)}</td>
                      <td className="align-middle text-center">{row.downloadCount}</td>
                      <td className="align-middle text-center">{formatDateTime(row.createdAt)}</td>
                    </tr>
                  ))}
                  </tbody>
                  <tfoot>
                  <SoftwareTableHead />
                  </tfoot>
                </table>
              </div>
            </Row>
            <Row>
              <PaginationView
                currentPage={currentPage}
                lastPage={lastPage}
                itemViewCount={itemViewCount}
                itemCount={itemCount}
                setCurrentPage={setCurrentPage}
              />
            </Row>
          </CardBody>
        ) : (
          <CardBody>
            <div>Пользователи отсутствуют!</div>
            <Link to={USERS_CREATE_PAGE_LINK}>Создайте аккаунт пользователя</Link>
          </CardBody>
        )}
      </Card>
    );
  }
;

export default SoftwarePage;
