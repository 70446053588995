import { FC, ReactNode, useContext } from "react";
import {
  ACCESS_LEVEL_ADMIN,
  ACCESS_LEVEL_CHIEF_ADMIN,
  ACCESS_LEVEL_MODERATOR,
  ACCESS_LEVEL_USER
} from "../core/constants";
import AuthContext from "../context/AuthContext";

type RoleType =
  typeof ACCESS_LEVEL_USER |
  typeof ACCESS_LEVEL_MODERATOR |
  typeof ACCESS_LEVEL_ADMIN |
  typeof ACCESS_LEVEL_CHIEF_ADMIN;

interface AdminAccessProps {
  children: ReactNode;
}

const AdminAccessWrapper: FC<AdminAccessProps> = ({ children }) => {
  const userRole = useContext(AuthContext).user.accessLevel;

  return (
    <>
      {userRole && userRole >= ACCESS_LEVEL_ADMIN && children}
    </>
  );
};

export default AdminAccessWrapper;