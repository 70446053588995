export interface IPlugin {
  smtcp: boolean;
  opcUa: boolean;
  modbus: boolean;
  redundancy: boolean;
  elna: boolean;
  onlineTransfer: boolean;
  archiver: boolean;
}

export interface ILicense {
  ID: number;
  licenseNumber: string;
  dateOfIssue: string;
  expirationDate: string;
  licVersion: number;
  contractID: number;
  pluginID: number;
  licFileName: string;
  ccbOldFileName: string | null;
  ccbFileName: string | null;
  loadedCcb: boolean;
  createdAt: string;
  issueComplete: boolean;
  issueDate: string | null;
  description: string | null;
  plugins: IPlugin;
}

export const pluginInitialState = {
  smtcp: false,
  opcUa: false,
  modbus: false,
  redundancy: false,
  elna: false,
  onlineTransfer: false,
  archiver: false
};

export const licenseInitialState = {
  ID: 0,
  licenseNumber: "",
  dateOfIssue: "",
  expirationDate: "",
  licVersion: 0,
  contractID: 0,
  pluginID: 0,
  licFileName: "",
  ccbOldFileName: "",
  ccbFileName: "",
  loadedCcb: false,
  createdAt: "",
  issueComplete: false,
  issueDate: "",
  description: "",
  plugins: pluginInitialState
};

export interface ILicenseDataInput {
  companyID: number;
  contractNumber: string;
  isOrderNumberExist: boolean;
  dateOfConclusion: string;
  orders: [
    {
      id: number;
      orderNumber: string;
      licCount: number;
      licVersion: number;
      hwFix: boolean;
      neverExpire: boolean;
      expirationDate: string;
      description: string;
      plugins: {
        smtcp: false;
        opc_ua: false;
        modbus: false;
        redundancy: false;
        elna: false;
        online_transfer: false;
        archiver: false;
      };
    }
  ];
}

export const dataInitialStateInput: ILicenseDataInput = {
  companyID: 0,
  contractNumber: "",
  isOrderNumberExist: false,
  dateOfConclusion: "",
  orders: [
    {
      id: 0,
      orderNumber: "",
      licCount: 0,
      licVersion: 0,
      hwFix: false,
      neverExpire: false,
      expirationDate: "",
      description: "",
      plugins: {
        smtcp: false,
        opc_ua: false,
        modbus: false,
        redundancy: false,
        elna: false,
        online_transfer: false,
        archiver: false
      }
    }
  ]
};

export const pluginInitialStateInput: IPlugin = {
  smtcp: false,
  opcUa: false,
  modbus: false,
  redundancy: false,
  elna: false,
  onlineTransfer: false,
  archiver: false
};

export const companyInitialStateInput = {
  id: 0,
  name: ""
};

export interface ILicenseWithUser {
  ID: number;
  licenseNumber: string;
  expirationDate: string;
  hwFix: boolean;
  licVersion: number;
  ccbOldFileName: string | null;
  ccbFileName: string | null;
  licFileName: string;
  issueComplete: boolean;
  issueDate: string | null;
  userID: number;
  userName: string;
  userSurname: string;
}

export const licenseWithUserInitialState: ILicenseWithUser = {
  ID: 0,
  licenseNumber: "",
  expirationDate: "",
  hwFix: false,
  licVersion: 0,
  ccbOldFileName: "",
  ccbFileName: "",
  licFileName: "",
  issueComplete: false,
  issueDate: "",
  userID: 0,
  userName: "",
  userSurname: ""
};

export interface ApiLicenseWithUserResponse {
  count: number;
  currentPage: number;
  lastPage: number;
  companyName: string;
  contractNumber: string;
  data: ILicenseWithUser[];
}

export interface ILicenseInfo {
  ID: number;
  LicenseNumber: string;
  HwFix: boolean;
  NeverExpire: boolean;
  DateOfIssue: string;
  ExpirationDate: string;
  LicVersion: number;
  IssueComplete: boolean;
  IssueDate: string;
  Description: string;
  UpdatedAt: string;
  CreatedAt: string;
  CompanyName: string;
  ContractNumber: string;
  UserName: string;
  UserSurname: string;
  UserEmail: string;
  CcbOldFileName: string;
  CcbFileName: string;
  LicFileName: string;
  Plugins: {
    Smtcp: boolean;
    OpcUa: boolean;
    Modbus: boolean;
    Redundancy: boolean;
    Elna: boolean;
    OnlineTransfer: boolean;
    Archiver: boolean;
  };
}

export const licenseInfoInitialState = {
  ID: 0,
  LicenseNumber: "",
  HwFix: false,
  NeverExpire: false,
  DateOfIssue: String(new Date()),
  ExpirationDate: String(new Date()),
  LicVersion: 0,
  IssueComplete: false,
  IssueDate: String(new Date()),
  Description: "",
  UpdatedAt: String(new Date()),
  CreatedAt: String(new Date()),
  CompanyName: "",
  ContractNumber: "",
  UserName: "",
  UserSurname: "",
  UserEmail: "",
  CcbOldFileName: "",
  CcbFileName: "",
  LicFileName: "",
  Plugins: {
    Smtcp: false,
    OpcUa: false,
    Modbus: false,
    Redundancy: false,
    Elna: false,
    OnlineTransfer: false,
    Archiver: false
  }
};
