import { FC } from 'react';
import { getDate } from '../../../../utils/date';
import { useTranslation } from 'react-i18next';

interface ExpDateRowProps {
  expDate: string;
}

const ExpDateRow: FC<ExpDateRowProps> = ({ expDate }) => {
  const { t } = useTranslation();

  return (
    <td className={'align-middle text-center'}>
      {getDate(expDate) == '31.12.9999' ? t('indefinitely-text') : getDate(expDate)}
    </td>
  );
};

export default ExpDateRow;