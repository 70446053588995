import { FC } from 'react';

import cn from 'classnames';

interface GearWideIconProps {
  className?: string;
}

const GearWideIcon: FC<GearWideIconProps> = ({ className }) => {
  return (
    <i className={cn('fs-4 bi bi-gear-wide', className)} />
  );
};

export default GearWideIcon;