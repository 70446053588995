import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface NavItemProps {
  link: string;
  text: string;
}

const NavItem: FC<NavItemProps> = ({ link, text }) => {
  const location = useLocation();

  return (
    <li className="nav-item">
      <Link
        className={`nav-link ${
          link === location.pathname
            ? 'disabled'
            : 'active'
        }`}
        to={link}
      >
        {text}
      </Link>
    </li>
  );
};

export default NavItem;
