export type IChangeEntry = {
  oldValue: string | number | boolean | null;
  newValue: string | number | boolean | null;
  changed: boolean;
};

export type IChanges = Record<string, IChangeEntry>;

export type IAuditLogEntry = {
  ID: number;
  tableName: string;
  operation: "INSERT" | "UPDATE" | "DELETE";
  changes: IChanges;
  changedOn: string;
};

export interface IAuditLogEntryResponse {
  data: IAuditLogEntry[];
  count: number;
  currentPage: number;
  lastPage: number;
}

export const auditLogEntryInitialState: IAuditLogEntry = {
  ID: 0,
  tableName: "",
  operation: "INSERT",
  changes: {},
  changedOn: "2024-08-20T15:42:03.66669Z"
};
