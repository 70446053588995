import { CSSProperties, FC, ReactNode } from 'react';
import cn from 'classnames';

interface RowProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties | undefined;
}

const Row: FC<RowProps> = ({ children, className, style }) => {
  return (
    <div
      className={cn('row', className)}
      style={{ ...style }}
    >
      {children}
    </div>
  );
};

export default Row;
